import React from 'react';
import { Link } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import { Fab } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import { styles } from "../../styles";
import LeftMenuCopy from './LeftMenuCopy';
import './Footer.scss';

export default function Footer(props: any) {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent
  ) => {
    setOpen(open);
  };

  return (
    <div className='footerContent'>
      <div className='content2'>
        <span style={{ width: 'fit-content', margin: '0 auto' }}>
          <Link to={props.route}>
            <Fab className='fabButton'>
              <AddIcon />
            </Fab>
          </Link>
        </span>
        <div className='nav'>
          <div>
            <Button color='primary' onClick={toggleDrawer(true)}>
              <img src={'/assets/menu.svg'} alt='' />
            </Button>
            <Button color='primary'>
              <img src={'/assets/bell.svg'} alt='' />
            </Button>
          </div>
          <div>
            <Link to='/messageList' style={styles.link}>
              <Button color='primary'>
                <img src={'/assets/message.svg'} alt='' />
              </Button>
            </Link>
            <Link to='/search' style={styles.link}>
              <Button color='primary'>
                <img src={'/assets/search_white.svg'} alt='' />
              </Button>
            </Link>
          </div>
        </div>
        <div className='divider'>
          <Divider />
        </div>
        <LeftMenuCopy open={open} onClose={toggleDrawer(false)} />
      </div>
    </div>
  );
}
