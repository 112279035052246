import TextField from '@material-ui/core/TextField';
import { Link, useHistory } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { useForm } from 'react-hook-form';
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";

import Header from '../../components/Header/Header';
import { styles, RedButton } from '../../styles';
import './index.scss';
import { useContext } from 'react';
import { Context } from 'src/context';
import { checkUser } from 'src/actions';
import { collection, query, where, getDocs } from "firebase/firestore";


export default function Register() {

  const history = useHistory();
  const { dispatch, db } = useContext(Context);

  const { register, handleSubmit, } = useForm({
    defaultValues: {
      name: '',
      email: '',
      password: '',
    }
  });

  const registerWithEmailAndPassword = handleSubmit(async (e: any) => {
    const { name, email, password } = e;
    const auth = getAuth();

    await createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential: any) => {
        const user = userCredential.user;
        db.collection("users").add({
          uid: user.uid,
          displayName: name,
          authProvider: "local",
          email,
          password
        });
        getUser(user.uid);
        history.push('/groupList');
      })
      .catch((error: any) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log('error ', errorCode, errorMessage);
      })
  });

  const getUser = async(userId :string) => {
    const q = query(collection(db, "users"), where("uid", "==", userId));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const user = doc.data();
      dispatch(
        checkUser({
          id: user.uid,
          login: user.displayName,
          password:user.password,
          image: user.photoURL,
          phone: user.phone,
          email:user.email,
        }))
    });
  }

  return (
    <>
      <Header title='Назад' />
      <div className='register'>
        <span style={styles.title}>
          <h3 style={styles.h3}>Регистрация</h3>
        </span>
        <form className='form' onSubmit={registerWithEmailAndPassword}>
          <List>
            <ListItem button className='items'>
              <TextField
                fullWidth
                label='Имя'
                variant='outlined'
                maxRows={2}
                size='small'
                {...register('name', { required: true })}
              />
            </ListItem>
            <ListItem button className='items'>
              <TextField
                fullWidth
                label='Email'
                variant='outlined'
                multiline
                maxRows={4}
                size='small'
                {...register('email', { required: true })}
              />
            </ListItem>
            <ListItem button className='items'>
              <TextField
                fullWidth
                label='Пароль'
                variant='outlined'
                multiline
                maxRows={4}
                size='small'
                {...register('password', { required: true })}
              />
            </ListItem>
            <ListItem button className='items'>
              <TextField
                fullWidth
                label='Повторите пароль'
                variant='outlined'
                multiline
                maxRows={4}
                size='small'
                {...register('password', { required: true })}
              />
            </ListItem>
          </List>

          <RedButton type='submit' onClick={registerWithEmailAndPassword}>
            Регистрация
          </RedButton>

          <span style={{ display: 'flex', justifyContent: 'center', margin: '24px 0' }} className='subTitle'>
            <Link to='/login' style={styles.link} className='subTitle'>
              Уже есть аккаунт? Войти
            </Link>
          </span>
          <div className='messagers'>
            <img src='assets/google.svg' alt='google' />
            <img src='/assets/facebook.svg' alt='facebook' />
            <img src='/assets/linkedin.svg' alt='linkedin' />
          </div>
        </form>
      </div>
    </>
  );
}