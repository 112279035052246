import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core';

import { styles } from "../../../../styles";
import ParticipantListItem from './ParticipantListItem';
import ParticipantAction from './ParticipantAction';
import { Context } from 'src/context';

const useStyles = makeStyles({
  root: {
    height: '100%',
  },
  groupList: {
    height: 'calc(100vh - 200px)',
    overflowY: 'auto',
    boxShadow: 'none',
    paddingTop: '54px',
  },
});

export default function ParticipantList() {
  const classes = useStyles();
  const { state: { participant } } = useContext(Context);
  
  return (
    <div className={classes.root}>
      <span style={styles.blurTitle}>
        <h3 style={styles.h3}>Участники</h3>
          <ParticipantAction />
      </span>
      <div className={classes.groupList}>
        {participant.map(item => (
          <ParticipantListItem
            key={item.id}
            item={item}
          />
        ))}
      </div>
    </div>
  );
}
