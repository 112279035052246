import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Chip from '@material-ui/core/Chip';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';

import { COLORS, RedButton, RedCheckbox, styles } from '../../../../styles';
import TariffPlanAction from '../TariffPlanAction';
import { data } from '../../../../data';
import { Context } from '../../../../context';
import { deleteGroup, displayGroupEvent } from '../../../../actions';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles({
    root: {
        height: '65vh',
        overflow: 'scroll',
        paddingBottom: '150px',
        '& div.MuiDialog-paper': {
            padding: '16px',
        },
        '& div.MuiListItemText-root': {
            flex: 1,
        },
        '& span.MuiTypography-root.MuiListItemText-primary.MuiTypography-body1.MuiTypography-displayBlock': {
            fontFamily: "Lato",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "14px",
            lineHeight: "20px",
            letterSpacing: "0.1px",
            color: COLORS.darkGrey,
        },
    },
    icon: {
        minWidth: '40px',
        marginRight: '16px'
    },
    title: {
        height: '20px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        textAlign: 'left'
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
    },
    col: {
        display: 'flex',
        flexDirection: 'column',
    },
    button: {
        marginTop: '30px',
        height: '100px',
    },
});

export default function Setting() {
    const classes = useStyles();
    const { state: { groupEvent, currentGroup }, dispatch } = useContext(Context);
    const [open, setOpen] = React.useState(false);
    const [selectedValue, setSelectedValue] = React.useState(data.tariffPlansType[0].label);
    const history = useHistory();
    
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value: any) => {
        setOpen(false);
        setSelectedValue(value);
    };

    const handleOnChange = (event: any, option: any) => {
        groupEvent.forEach((group) => {
            if (group === option) {
                if (event.target.name === 'viewInDesktop') {
                    group.viewInDesktop = event.target.checked;
                } else {
                    group.viewInPreviewGroup = event.target.checked;
                }

                dispatch(displayGroupEvent({
                    id: option.id,
                    name: option.name,
                    image: option.image,
                    action: option.action,
                    route: option.route,
                    viewInDesktop: group.viewInDesktop,
                    viewInPreviewGroup: group.viewInPreviewGroup,
                }));
            }
        });
    }

    const deleteHandler = (id: any) => {
        dispatch(deleteGroup(id));
        history.push('/groupList')
    };

    return (
        <div className={classes.root}>
            <span style={styles.title}>
                <h3 style={styles.h3}>Настройки</h3>
            </span>

            <div className={classes.col}>
                <ListItem>
                    <ListItemIcon className={classes.icon}>
                        <img src='/assets/currencyDollar.svg' alt='' />
                    </ListItemIcon>
                    <ListItemText primary='Тарифный план' />
                    <span style={{ margin: '0 0 0 auto' }}>
                        <Chip style={styles.chip} label={selectedValue} onClick={handleClickOpen} />
                    </span>
                </ListItem>
            </div>

            <TariffPlanAction selectedValue={selectedValue} open={open} onClose={handleClose} />

            <div className={classes.col}>
                <span className={classes.title}>
                    <h5 style={styles.h5}>Отображение настроек (меню/группа)</h5>
                </span>

                <List style={{ width: '100%' }}>
                    {groupEvent.map((item) =>
                        <ListItem key={item.id}>
                            <ListItemIcon className={classes.icon}>
                                <img src={item.image} alt='' />
                            </ListItemIcon>
                            <ListItemText primary={item.name} />
                            <FormControlLabel
                                control={
                                    <RedCheckbox
                                        checked={item.viewInDesktop}
                                        value={item.id}
                                        onChange={(e) => handleOnChange(e, item)}
                                        name='viewInDesktop'
                                    />
                                }
                                label=''
                                labelPlacement="start"
                            />

                            <FormControlLabel
                                control={
                                    <RedCheckbox
                                        checked={item.viewInPreviewGroup}
                                        value={item.id}
                                        onChange={(e) => handleOnChange(e, item)}
                                        name='viewInPreviewGroup'
                                    />
                                }
                                label=''
                                labelPlacement="start"
                            />
                        </ListItem>
                    )}
                </List>
            </div>

            <div className={classes.row}>
                <div className={classes.col} style={{ width: '100%' }}>
                    <span className={classes.title}>
                        <h5 style={styles.h5}>Участники</h5>
                    </span>
                    <List>
                        <ListItem button>
                            <ListItemIcon className={classes.icon}>
                                <img src='/assets/userGroup.svg' alt='' />
                            </ListItemIcon>
                            <ListItemText primary='Количество участников' style={{ margin: '0 auto 0 0' }} />
                            <ListItemText primary='10' />
                        </ListItem>
                        <ListItem button>
                            <ListItemIcon className={classes.icon}>
                                <img src='/assets/person.svg' alt='' />
                            </ListItemIcon>
                            <ListItemText primary='Изменить главу' />
                        </ListItem>
                        <ListItem button onClick={() => deleteHandler(currentGroup.id)}>
                            <ListItemIcon className={classes.icon}>
                                <DeleteOutlineOutlinedIcon style={{ color:'#93989f'}}/>
                            </ListItemIcon>
                            <ListItemText primary='Покинуть/удалить группу' />
                        </ListItem>
                    </List>
                </div>
            </div>
            <div className={classes.button} >
                <RedButton>
                    Бизнес Процессы
                </RedButton>
            </div>
        </div>
    );
}
