import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from '@material-ui/core/Icon';
import CloseIcon from '@material-ui/icons/Close';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';

import { COLORS, styles } from "../../../../styles";

const useStyles = makeStyles({
    root: {
        '& div.MuiDialog-paper': {
            padding: '16px',
        },
        "& span.material-icons": {
            marginRight: "20px"
        },
    },
    dialogContent: {
        padding: 0,
    },
    button: {
        padding: '8px'
    },
    icon: {
        color: COLORS.lightGrey,
    }
});

export default function ParticipantAction() {
    const classes = useStyles();
    const [state, setState] = React.useState({
        open: false,
        checkedA: true,
        checkedB: false
    });

    const handleClickOpen = () => {
        setState({ ...state, open: true });
    };

    const handleClose = () => {
        setState({ ...state, open: false });
    };

    return (
        <div>
            <IconButton onClick={handleClickOpen} className={classes.button}>
                <MoreVertIcon />
            </IconButton>

            <Dialog
                open={state.open}
                onClose={handleClose}
                className={classes.root}
            >
                <IconButton aria-label="delete" onClick={handleClose} style={{ margin: '0 0 0 auto' }}>
                    <CloseIcon />
                </IconButton>
                <DialogContent className={classes.dialogContent}>
                    <List>
                        <Link to='/deleteParticipant' style={styles.link}>
                            <ListItem button >
                                <Icon className={classes.icon}>
                                    <DeleteOutlineOutlinedIcon />
                                </Icon>
                                <ListItemText
                                    primary='Удалить участника'
                                />
                            </ListItem>
                        </Link>
                        <Link to='/addParticipant' style={styles.link}>
                            <ListItem button>
                                <Icon className={classes.icon}>
                                    <AddOutlinedIcon />
                                </Icon>
                                <ListItemText
                                    primary='Добавить участника'
                                />
                            </ListItem>
                        </Link>
                    </List>
                </DialogContent>
            </Dialog>
        </div>
    );
}
