import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core';
import { COLORS, styles } from "../../../../styles";
import DescriptionAction from './DescriptionAction';
import { Context } from '../../../../context';

const useStyles = makeStyles({
    root: {
        '& div.MuiDialog-paper': {
            padding: '16px',
        }
    },
    description: {
        '& p': {
            fontFamily: 'Lato',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '14px',
            lineHeight: '24px',
            letterSpacing: '0.15px',
            color: COLORS.lightGrey,
            margin: 0,
            textAlign: 'start'
        },
    },
});

export default function Description() {
    const classes = useStyles();
    const { state: { currentGroup }} = useContext(Context);

    return (
        <div>
            <span style={styles.title}>
                <h3 style={styles.h3}>Описание</h3>
                <DescriptionAction/>
            </span>
            <div className={classes.description}>
                <p>
                    {currentGroup.description}
                </p>
            </div>
        </div>
    );
}
