import * as React from 'react';
import { makeStyles } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';

import { COLORS, styles } from '../../styles';
import SearchAction from './SearchAction';
import Header from '../Header/Header';
import { data } from '../../data';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        padding: '80px 16px 0 16px',
    },
    card: {
        borderRadius: '8px',
        marginTop: '16px',
        boxShadow: '0px 12px 20px rgb(156 21 21 / 7%)',
        height: 'fit-content',
        background: COLORS.white,
        '&:hover': {
            background: COLORS.lightGreyTransparent,
        },
    },
});


export default function SearchResult() {
    const classes = useStyles();

    return (
        <>
        <Header title='Назад' />
        <div className={classes.root}>
            <span style={styles.title}>
                <h3 style={styles.h3}>Результаты поиска</h3>
            </span>
            <List>
                {data.searchResultGroup.map(item => (
                    <ListItem className={classes.card} key={item.id}>
                        <ListItemAvatar style={styles.avatar}>
                            <Avatar >
                                <img
                                    alt=''
                                    src={item.image}
                                />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={item.name} />
                        <SearchAction/>
                    </ListItem>
                ))}
            </List>
        </div>
        </>

    );
}
