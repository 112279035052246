import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import GridOnOutlined from '@material-ui/icons/GridOnOutlined';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import { Link } from 'react-router-dom';
import Chip from '@material-ui/core/Chip';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';

import { COLORS, styles } from '../../styles';
import Header from '../Header/Header';
import GroupAction from './GroupAction';
import Footer from '../Footer/Footer';
import { Context } from '../../context';
import { Group } from '../../interface';
import { selectCurrentGroup } from 'src/actions';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    padding: '80px 0 0 0',
    '& p.MuiTypography-root.MuiListItemText-secondary.MuiTypography-body2.MuiTypography-colorTextSecondary.MuiTypography-displayBlock': {
      margin: "0",
      display: "-webkit-box",
      overflow: "hidden",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: "2"
    },
    '& span.MuiTypography-root.MuiListItemText-primary.MuiTypography-body1.MuiTypography-displayBlock': {
      margin: "0",
      display: "-webkit-box",
      overflow: "hidden",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: "2"
    }
  },
  toggleButton: {
    border: 'none',
    '&.MuiToggleButton-root.Mui-selected:hover': {
      backgroundColor: 'transparent',
      color: COLORS.darkGrey,
    },
    '&.MuiToggleButton-root.Mui-selected': {
      backgroundColor: 'transparent',
      color: COLORS.darkGrey,
    },
  },
  list: {
    height: 'calc(100vh - 250px)',
    overflowY: 'auto',
    boxShadow: 'none',
    padding: '54px 0 0 16px',
    '&.MuiList-padding': {
      paddingBottom: '110px'
    },
  },
  listItem: {
    borderRadius: '8px',
    margin: '16px 16px 0 0',
    boxShadow: '0px 12px 20px rgb(156 21 21 / 7%)',
    height: 'fit-content',
    background: COLORS.white,
    '&:hover': {
      background: COLORS.lightGreyTransparent,
    },
    '& li.listItemCard': {
      margin: '16px 0 16px 0',
    },
  },
  listItemCard: {
    display: 'flex',
    width: '145px',
    height: '150px',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    '& div.MuiListItemAvatar-root': {
      order: '-1',
      marginRight: '0!important'
    },
    '& span': {
      order: '-1'
    },
    '& span button.MuiIconButton-root': {
      order: '-1',
    },

  },
  noData: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
  },
  title: {
    marginLeft: '16px'
  }
});

const col: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
};

const row: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
};

export default function GroupList() {
  const classes = useStyles();
  const [display, setDisplay] = React.useState(row);
  const { state: { group }, dispatch } = useContext(Context);

  const handleDisplay = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: any
  ) => {
    if (newAlignment !== null) {
      setDisplay(newAlignment);
    }
  };

  const selectCurretGroup = (data: Group) => {
    dispatch(selectCurrentGroup(data));
  };

  return (
    <>
      <Header title='Мои Группы' />
      <div className={classes.root}>
        <span style={styles.blurTitle} className={classes.title}>
          <h3 style={styles.h3}>Мои Группы</h3>
          <ToggleButtonGroup
            value={display}
            exclusive
            onChange={handleDisplay}
          >
            <ToggleButton value={row} className={classes.toggleButton} >
              <FormatListBulletedIcon />
            </ToggleButton>
            <ToggleButton value={col} className={classes.toggleButton}>
              <GridOnOutlined />
            </ToggleButton>
          </ToggleButtonGroup>
        </span>

        {group.length > 0 ? (
          <List className={classes.list} style={display}>
            {group.map((item: Group) => (
              <div
                className={classes.listItem} key={item.id}
              >
                <ListItem
                  className={display.flexDirection === 'row' ? classes.listItemCard : ''}
                  onClick={() => { selectCurretGroup(item) }}
                >
                  <ListItemAvatar style={styles.avatar}>
                    <img
                      alt={item.name}
                      src={item.image}
                      style={{ width: 30 }}
                    />
                  </ListItemAvatar>
                  <Link to='/desktop' style={styles.link}>
                    <ListItemText
                      primary={item.name}
                      secondary={item.description}

                    />
                  </Link>
                  <span style={{ margin: '0 0 0 auto' }}>
                    {/* {testCount.map((item) => (
                      <Chip style={styles.chip} label={item} />
                    ))} */}
                    {item.action && item.action > 0 ?
                      <Chip style={styles.chip} label={item.action} /> : null}
                  </span>
                  <span>
                    <GroupAction {...item} />
                  </span>
                </ListItem>
              </div>
            ))}
          </List>
        ) : (
          <div className={classes.list}>
            <span className={classes.noData}>
              <h3 style={styles.h3}>Добавьте новую группу</h3>
            </span>
          </div>
        )}
      </div>
      <Footer route='/newGroupCopy' />
    </>
  );
}
