import React, { useContext } from 'react';
import { makeStyles, IconButton } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';

import { styles, COLORS } from "../../../../styles";
import Header from '../../../Header/Header';
import { data } from '../../../../data';
import { addParticipant } from 'src/actions';
import { Context } from 'src/context';
import { Participant } from 'src/interface';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    padding: '80px 16px 0 16px',
    '& div.MuiListItemText-root': {
      width: '100%',
    },
  },
  card: {
    borderRadius: '8px',
    marginTop: '16px',
    boxShadow: '0px 12px 20px rgb(156 21 21 / 7%)',
    height: 'fit-content',
    background: COLORS.white,
    '&:hover': {
      background: COLORS.lightGreyTransparent,
    },
  },
});

export default function RequestParticipant() {
  const classes = useStyles();
  const history = useHistory();
  const { dispatch } = useContext(Context);

  const addParticipantHandler = (data: Participant) => {

    dispatch(addParticipant({
      id: 1,
      name: data.name,
      image: '/assets/slot.png',
      description: 'родитель',
    }));
    history.push('/groupSettings', 2)
  };

  return (
    <div className={classes.root}>
      <Header title='Назад' />
      <span style={styles.title}>
        <h3 style={styles.h3}>Запросы на участие</h3>
      </span>
      <List>
        {data.newParticipants.map(item => (
          <ListItem className={classes.card} key={item.id}>
            <ListItemAvatar style={styles.avatar}>
              <Avatar >
                <img
                  alt=''
                  src={item.image}
                />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={item.name} />
            <IconButton
              edge="start"
              color="secondary"
              onClick={() => { addParticipantHandler(item) }}
            >
              <CheckOutlinedIcon />
            </IconButton>
              <IconButton
                onClick={() => {  history.push('/groupSettings', 2)}}
                edge="start"
              >
                <CloseOutlinedIcon />
              </IconButton>
          </ListItem>
        ))}
      </List>
    </div>
  );
}
