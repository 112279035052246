import { makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from '@material-ui/core/Icon';
import Chip from '@material-ui/core/Chip';

import { COLORS, styles } from '../../styles';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import { useContext } from 'react';
import { Context } from '../../context';
import { Task } from '../../interface';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        padding: '80px 16px 0 16px',
        '& span.MuiTypography-root.MuiListItemText-primary.MuiTypography-body1.MuiTypography-displayBlock': {
            fontFamily: "Lato",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "14px",
            lineHeight: "20px",
            letterSpacing: "0.1px",
            color: COLORS.darkGrey,
        },
    },
    button: {
        marginTop: '30px',
        '& button': {
            width: '328px',
            padding: '16px 0px',
            color: COLORS.white,
            backgroundColor: COLORS.red,
            boxShadow: '0px 20px 30px rgba(148, 0, 0, 0.32), inset 0px 0px 3px rgba(148, 0, 0, 0.44)',
            borderRadius: '47px',
            '&:hover': {
                color: COLORS.white,
                backgroundColor: COLORS.red,
                boxShadow: '0px 20px 30px rgba(148, 0, 0, 0.32), inset 0px 0px 3px rgba(148, 0, 0, 0.44)',
            },
            '& span': {
                fontFamily: 'Lato',
                fontStyle: 'normal',
                fontWeight: '600',
                fontSize: '16px',
                lineHeight: '24px',
                display: 'flex',
                alignItems: 'center',
                letterSpacing: '1px',
                textTransform: 'uppercase',
                textDecoration: 'none',
            }
        }
    },
    listContent: {
        padding: 0,
        width: '100%',
        '& span.material-icons': {
            marginRight: '20px'
        },
        '& div.MuiListItem-gutters': {
            paddingLeft: 0,
            paddingRight: 0,
        },
    },
    notData: {
        fontFamily: "'Montserrat'",
        color: "#95a6b7",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "28px",
        display: "flex",
        alignItems: "center",
        letterSpacing: "0.15px",
        margin: "0 0 0 10px"
    }
});

export default function TaskList() {
    const { state: { currentGroup } } = useContext(Context);
    const classes = useStyles();
    const { state: { task } } = useContext(Context);
    const dataTask = task.filter((item: Task) => item.groupId === currentGroup.id);

    return (
        <>
            <Header title={currentGroup.name} />
            <div className={classes.root}>
                <span style={styles.title}>
                    <h3 style={styles.h3}>Задачи</h3>
                </span>
                {dataTask && dataTask.length > 0 ? (
                    <List>
                        {dataTask.map((item: any) => (
                            <Link
                                key={item.id}
                                to={`${item.route}/${item.id}`}
                                style={styles.link}>
                                <ListItem key={item.id} button>
                                    <Icon >
                                        <img
                                            src={item.image}
                                            alt={item.name}
                                        />
                                    </Icon>
                                    <ListItemText primary={item.name} />
                                    {item.action && item.action > 0 ?
                                        <Chip style={styles.chip} label={item.action} /> : null}
                                </ListItem>
                            </Link>
                        ))}
                    </List>
                ) : (
                    <div className={classes.notData}>
                        <h3>Нет задач</h3>
                    </div>
                )}
            </div>
            <Footer route='/newTask' />
        </>
    );
}
