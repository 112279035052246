import React, { useReducer } from 'react';
import { auth, db, app } from 'src/config/firebase';
import { data, tasks, groups, participants, groupEvents, user } from '../data';
import {
  ActionType,
  AppState,
  Action,
  Group,
  AppModel,
  Task,
  Participant,
  User,
} from '../interface';


const defaultState: AppState = {
  group: [],
  currentGroup: {},
  task: tasks,
  isNotification: false,
  isAnchor: false,
  groupEvent: groupEvents,
  participant: participants,
  user: user
};

export const reducer = (
  state: AppState,
  action: Action,
): AppState => {
  switch (action.type) {

    case ActionType.ADD_GROUP: {
      let sequenceId = groups.length + 1;
      const newGroup: Group = {
        ...action.payload as Group,
        ...{
          id: sequenceId,
        },
      } as Group;
      sequenceId += 1;
      return {
        ...state,
        group: [newGroup, ...state.group],
      };
    }

    case ActionType.EDIT_DES_GROUP: {
      const editGroup = action.payload as Group;
      Object.assign(state.currentGroup, editGroup);
      return {
        ...state,
        group: state.group,
      };
    }

    case ActionType.DELETE_GROUP: {
      return {
        ...state,
        group: state.group.filter((item) => item.id !== action.payload as number),
      }
    }

    case ActionType.CHECK_USER: {

      // console.log('CHECK_USER', action.payload);
      
      let user = action.payload as User;
      // let currentUser = defaultState.user;
      let dataGroups = data.groups as Group[];

      if (user.email === 'demoUser@gmail.com' && user.password === '123456') {
        dataGroups = data.groups as Group[];
        // user = currentUser;
      } else {
        dataGroups = [];
        let sequenceId = 10;
        const newUser: User = {
          ...action.payload as User,
          ...{
            id: action.payload.id ? action.payload.id : sequenceId,
          },
        } as User;
        sequenceId += 1;
        user = newUser
      }
      return {
        ...state,
        group: dataGroups,
        user: user
      };
    }

    case ActionType.EDIT_USER_PROFILE: {
      const user = action.payload as User;
      Object.assign(state.user, user);
      return {
        ...state,
        user: state.user,
      };
    }

    case ActionType.COMPLETE_TASK: {
      return {
        ...state,
        task: state.task.filter((item) => item.id !== action.payload as number),
      }
    }

    case ActionType.ADD_TASK: {
      let sequenceId = tasks.length + 1;
      const newTask: Task = {
        ...action.payload as Task,
        ...{
          id: sequenceId,
        },
      } as Task;
      sequenceId += 1;
      return {
        ...state,
        task: [newTask, ...state.task],
      };
    }

    case ActionType.DISPLAY_GROUP_EVENT: {
      return {
        ...state,
        groupEvent: groupEvents,
      };
    }

    case ActionType.ENABLE_NOTIFICATION: {
      return {
        ...state,
        isNotification: action.payload,
      };
    }

    case ActionType.PIN_GROUP: {
      const idx = state.group.findIndex(currentValue => currentValue.id === state.currentGroup.id);
      state.group.unshift(...state.group.splice(idx, 1));
      return {
        ...state,
        isAnchor: action.payload,
      };
    }

    case ActionType.ADD_PARTICIPANT: {
      let sequenceId = participants.length + 10;

      const newParticipant: Participant = {
        ...action.payload as Participant,
        ...{
          id: sequenceId,
        },
      } as Participant;

      sequenceId += 1;

      return {
        ...state,
        participant: [newParticipant, ...state.participant],
      };
    }

    case ActionType.DELETE_PARTICIPANT: {
      action.payload.reverse().forEach(el => state.participant.splice(el, 1));

      return {
        ...state,
        participant: state.participant,
      }
    }

    case ActionType.CURRENT_GROUP: {
      return {
        ...state,
        currentGroup: action.payload,
      }
    }

    default:
      return state;
  }
};



export const Context = React.createContext({} as AppModel);

export const Provider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, defaultState);

  return (
    <Context.Provider value={{ state, dispatch, app, auth, db }}>{children}</Context.Provider>
  );
};
