import React, { useContext, useState } from 'react';
import { Button, makeStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import InputMask from "react-input-mask";
import { COLORS, styles } from '../../styles';
import { useHistory } from 'react-router-dom';
import firebase from "firebase/compat/app";
import { checkUser } from 'src/actions';
import { Context } from 'src/context';


const useStyles = makeStyles({
    root: {
        '& div.MuiDialog-paper': {
            padding: '16px',
        },
        '& span.MuiTypography-root.MuiListItemText-primary.MuiTypography-body1.MuiTypography-displayBlock': {
            fontFamily: "Lato",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "14px",
            lineHeight: "20px",
            letterSpacing: "0.1px",
            color: COLORS.darkGrey,
        },
    },
    dialogContent: {
        padding: 0,
        minHeight: 120,
        "& span.material-icons": {
            marginRight: "20px"
        },
    },
    button: {
        padding: '8px'
    },
    form: {
        marginTop: 20,
        '& div.MuiSelect-outlined': {
            textAlign: 'start'
        },
    }
});

declare global {
    interface Window {
        recaptchaVerifier: any;
        confirmationResult: any;
    }
}

export interface LoginWithPhoneActionProps {
    open: boolean;
    onClose: (value: any) => void;
}

export default function LoginWithPhoneAction(props: LoginWithPhoneActionProps) {
    const classes = useStyles();
    const history = useHistory();
    const { dispatch } = useContext(Context);

    const { onClose, open } = props;

    const [state, setState] = useState({
        displayFormWithOptCode: false,
        phone: '',
        otp: '123123'
    });

    const onSignInSubmit = (e?: any) => {
        e.preventDefault();
        setUpRecaptcha();
        let phoneNumber = state.phone;
        let appVerifier = window.recaptchaVerifier;
        firebase
            .auth()
            .signInWithPhoneNumber(phoneNumber, appVerifier)
            .then((confirmationResult) => {
                window.confirmationResult = confirmationResult;
                setState({ ...state, displayFormWithOptCode: true })
            })
            .catch((error) => {
                console.log('код не отправлен', error);
            });
    };

    const setUpRecaptcha = () => {
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
            "recaptcha-container",
            {
                size: "invisible",
                'callback': (response: any) => {
                    onSignInSubmit();
                },
            }
        );
    };

    const onSubmitOtp = (e: any) => {
        e.preventDefault();
        let otpInput = state.otp;
        let optConfirm = window.confirmationResult;
        optConfirm
            .confirm(otpInput)
            .then((result: any) => {
                let user = result.user;
                dispatch(
                    checkUser({
                      login: user.displayName,
                      password: user.password,
                      email: user.email,
                      image: user.photoURL,
                      id: user.uid,
                      phone: user.phoneNumber,
                    }))
                history.push('/groupList');
            })
            .catch((error: any) => {
                console.log('неверный код', error);
            });
    };

    return (
        <Dialog
            fullWidth
            maxWidth="sm"
            open={open}
            onClose={onClose}
            className={classes.root}
        >
            <span style={styles.title}>
                <h3 style={styles.h3}>Введите номер телефона</h3>
                <IconButton aria-label="delete" onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </span>
            <DialogContent className={classes.dialogContent}>
                <form className={classes.form} onSubmit={!state.displayFormWithOptCode ? onSignInSubmit : onSubmitOtp}>
                    <div id="recaptcha-container" style={{ display: 'none' }}></div>

                    <InputMask
                        mask="+999 99 999-99-99"
                        value={state.phone}
                        disabled={false}
                        onChange={(e) => setState({ ...state, phone: e.target.value })}
                    >
                        {() => <TextField fullWidth label='Номер телефона' variant='outlined' required  size='small'/>}
                    </InputMask>

                    {state.displayFormWithOptCode ? (
                        <TextField
                            style={{ marginTop: 20 }}
                            fullWidth
                            label='Код'
                            variant='outlined'
                            type="number"
                            size='small'
                            value={state.otp}
                            required
                            onChange={(e) => setState({ ...state, otp: e.target.value })}
                        />
                    ) : null}
                    <DialogActions>
                        <Button color="primary" type='submit'>
                            Отправить
                        </Button>
                    </DialogActions>
                </form>
            </DialogContent>
        </Dialog>
    );
}
