import * as React from 'react';
import { makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import { RedButton, styles } from '../../styles';
import Header from '../Header/Header';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        padding: '80px 16px 0 16px',
    },
    button: {
        width: '100%',
    },
    form: {
        '& div:first-child': {
            marginTop: '10px',
        },
    },
    field: {
        marginTop: '30px',
        '& div.MuiSelect-outlined': {
            textAlign: 'start'
        },
    }
});

export default function NewMessage() {
    const classes = useStyles();
    const history = useHistory();

    return (
        <div className={classes.root}>
            <Header title='Назад' />
            <div className={classes.form}>
                <TextField
                    fullWidth
                    label='Введите имя'
                    variant='outlined'
                    multiline
                    size='small'
                    className={classes.field}
                />
                <TextField
                    fullWidth
                    label='Введите сообщение'
                    variant='outlined'
                    multiline
                    minRows={15}
                    maxRows={15}
                    size='small'
                    className={classes.field}
                />
            </div>
            <div style={styles.row}>
                <span className={classes.button} >
                    <RedButton onClick={() => { history.goBack() }}>
                        Отправить
                    </RedButton>
                </span>
            </div>
        </div>
    );
}
