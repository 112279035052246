import * as React from 'react';
import { makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import { RedButton, styles } from '../../styles';
import Header from '../Header/Header';
import { useContext } from 'react';
import { Context } from '../../context';
import { addTask } from '../../actions';
import { useForm } from 'react-hook-form';
import { TaskType } from '../../interface';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        padding: '80px 16px 0 16px',
    },
    form: {
        '& div:first-child': {
            marginTop: 0,
        },
    },
    field: {
        marginTop: '30px',
        '& div.MuiSelect-outlined': {
            textAlign: 'start'
        },
    },
    listItem: {
        padding: 0,
        marginBottom: '30px'
    },
});

export default function NewTask() {
    const { state: { currentGroup } } = useContext(Context);
    const classes = useStyles();
    const history = useHistory();
    const { dispatch } = useContext(Context);
    const { register, handleSubmit } = useForm({
        defaultValues: {
            description: '',
            type: TaskType.None,
            cash: ''
        }
    });
    const [type, setType] = React.useState(TaskType.None);

    const handleChangeType = (e: any) => {
        setType(e.target.value);
    };

    const submitHandler = handleSubmit((data: any) => {
        let route, taskImg;
        switch (data.type) {
            case 'Голосование': {
                route = '/voteListDetails';
                taskImg = '/assets/scale.svg';
                break;
            }
            case 'Оплата': {
                route = '/shoppingListDetails';
                taskImg = '/assets/shoppingBag.svg';
                break;
            }
            case 'Покупки': {
                route = '/shoppingListDetails';
                taskImg = '/assets/shoppingBag.svg';
                break;
            }
            default: {
                route = '';
                taskImg = '';
                break;
            }
        }

        dispatch(
            addTask({
                name: '' + data.type,
                description: data.description,
                image: taskImg,
                action: 0,
                route: route,
                groupId: currentGroup.id,
                cash: data.cash + 'руб.',
            })
        );
        history.goBack();
    });

    return (
        <>
            <Header title='Назад' />

            <div className={classes.root}>
                <span style={styles.title}>
                    <h3 style={styles.h3}>Создание задачи</h3>
                </span>
                <form className={classes.form} onSubmit={submitHandler}>
                    <List>
                        <ListItem button className={classes.listItem}>
                            <FormControl variant='outlined' fullWidth size='small'>
                                <InputLabel>Тип задачи</InputLabel>
                                <Select
                                    {...register('type')}
                                    value={type}
                                    onChange={handleChangeType}
                                    label='Тип задачи'
                                >
                                    <MenuItem value='Голосование'>Голосование</MenuItem>
                                    <MenuItem value='Оплата'>Оплата</MenuItem>
                                    <MenuItem value='Покупки'>Покупки</MenuItem>
                                </Select>
                            </FormControl>
                        </ListItem>

                        <ListItem button className={classes.listItem}>
                            <TextField
                                fullWidth
                                label='Краткое описание'
                                placeholder="Введите краткое описание"
                                variant='outlined'
                                multiline
                                minRows={10}
                                maxRows={10}
                                size='small'
                                className={classes.field}
                                {...register('description')}
                            />
                        </ListItem>

                        {type === (TaskType.Payment || TaskType.Shopping) &&
                            <ListItem button className={classes.listItem}>
                                <TextField
                                    fullWidth
                                    label='Сумма'
                                    placeholder="Введите сумму"
                                    variant='outlined'
                                    multiline
                                    minRows={1}
                                    size='small'
                                    className={classes.field}
                                    {...register('cash')}
                                />
                            </ListItem>
                        }
                    </List>
                    <span >
                        <RedButton type='submit'>
                            Создать задачу
                        </RedButton>
                    </span>
                </form>
            </div>
        </>

    );
}
