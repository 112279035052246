import { makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';

import { GreyButton, RedButton, styles } from '../../../styles';
import Header from '../../Header/Header';
import { useContext } from 'react';
import { Context } from 'src/context';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        padding: '80px 16px 0 16px',
    },
    button: {
        width: '100%',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between'
    },
    form: {
        '& div:first-child': {
            marginTop: 0,
        },
    },
    field: {
        marginTop: '30px',
        '& div.MuiSelect-outlined': {
            textAlign: 'start'
        },
    }
});

export default function NewVote() {
    const { state: { currentGroup } } = useContext(Context);
    const classes = useStyles();
    const history = useHistory();
    
    return (
        <>
            <Header title={currentGroup.name} />
            <div className={classes.root}>
                <span style={styles.title}>
                    <h3 style={styles.h3}>Создание голосования</h3>
                </span>
                <div className={classes.form}>
                    <TextField
                        fullWidth
                        label='Название голосования'
                        placeholder='Введите краткое описание'
                        variant='outlined'
                        multiline
                        size='small'
                        className={classes.field}
                    />
                    <TextField
                        fullWidth
                        label='Краткое описание'
                        placeholder='Введите краткое описание'
                        variant='outlined'
                        multiline
                        minRows={10}
                        maxRows={15}
                        size='small'
                        className={classes.field}
                    />
                </div>
                <div className={classes.row}>
                    <span className={classes.button} style={{ width: '156px' }}>
                        <GreyButton onClick={() => { history.goBack() }}>
                            Отмена
                        </GreyButton>
                    </span>
                    <span className={classes.button} style={{ width: '156px' }} >
                        <RedButton onClick={() => { history.goBack() }}>
                            Создать
                        </RedButton>
                    </span>
                </div>
            </div>
        </>
    );
}
