import * as React from 'react';
import { makeStyles, withStyles } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import Radio, { RadioProps } from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { useHistory, useParams } from 'react-router-dom';

import { COLORS, GreyButton, RedButton, styles } from '../../../styles';
import Header from '../../Header/Header';
import { useContext } from 'react';
import { Context } from '../../../context';
import { completeTask } from '../../../actions';
import { Task } from '../../../interface';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        padding: '80px 16px 0 16px',
        '& div.MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button ': {
            minHeight: '100px',
        },
        '& ul span.MuiTypography-body1': {
            fontFamily: 'Lato',
            fontStyle: 'normal',
            fontSize: '16px',
            lineHeight: '24px',
            letterSpacing: '0.15px',
            color: COLORS.lightGrey,
        },
        '& label span.MuiTypography-body1': {
            fontFamily: 'Lato',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '16px',
            lineHeight: '20px',
            display: 'flex',
            alignItems: 'flex-end',
            letterSpacing: '0.25px',
            color: COLORS.darkGrey,
        },
    },
    card: {
        display: 'flex',
        flexDirection: 'row',
        background: COLORS.white,
        boxShadow: '0px 12px 20px rgba(0, 0, 0, 0.07)',
        borderRadius: '8px',
        '& p': {
            fontFamily: 'Lato',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '14px',
            lineHeight: '20px',
            letterSpacing: '0.25px',
            color: COLORS.lightGrey,
            opacity: 0.6
        },
    },
    button: {
        width: '100%',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between'
    },
    col: {
        display: 'flex',
        flexDirection: 'column',
    },
});

const RedRadio = withStyles({
    root: {
        color: '#DADADA',
        '&$checked': {
            color: COLORS.red
        },
    },
    checked: {},
})((props: RadioProps) => <Radio color='default' {...props} />);


const itemActions = [
    {
        id: 1,
        label: 'Да',
    },
    {
        id: 2,
        label: 'Нет',
    },
    {
        id: 3,
        label: 'Нужно подумать',
    },
];

export default function VoteListDetails() {
    const classes = useStyles();
    const [value, setValue] = React.useState(itemActions[0].label);
    const { state: { task, currentGroup }, dispatch } = useContext(Context);
    const { id } = useParams<{ id: any }>();
    const history = useHistory();

    const currentTask = task.find((item: Task) => item.id === +id) as Task;
    
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue((event.target as HTMLInputElement).value);
    };

    const closeTask = (id: number) => {
        dispatch(completeTask(+id));
        history.goBack();
    };

    return (
        <>
            <Header title={currentGroup.name} />
            <div className={classes.root}>
                <span style={styles.title}>
                    <h3 style={styles.h3}>Голосование</h3>
                </span>
                <List >
                    <ListItem button className={classes.card} >
                        <ListItemText
                            primary={currentTask.description}
                        />
                    </ListItem>
                </List>
                {itemActions.map((item) => (
                    <FormControl component='fieldset' key={item.id} >
                        <RadioGroup name='vote' value={value} onChange={handleChange}>
                            <FormControlLabel value={item.label} control={<RedRadio />} label={item.label} />
                        </RadioGroup>
                    </FormControl>
                ))}
                <div className={classes.row}>
                    <span className={classes.button} style={{ width: '156px' }}>
                        <GreyButton onClick={() => { history.goBack() }}>
                            Отмена
                        </GreyButton>
                    </span>
                    <span className={classes.button} style={{ width: '156px' }}>
                        <RedButton onClick={() => closeTask(id)}>
                            Голосовать
                        </RedButton>
                    </span>
                </div>
                <div className={classes.row}>
                    <span className={classes.button} >
                        <GreyButton onClick={() => { history.push('/messageList') }}>
                            Обсудить
                        </GreyButton>
                    </span>
                </div>
            </div>
        </>
    );
}
