import React from 'react';
import { makeStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import CloseIcon from '@material-ui/icons/Close';

import { COLORS, RedRadio, styles } from '../../../styles';
import { data } from '../../../data';

const useStyles = makeStyles({
    root: {
        '& div.MuiDialog-paper': {
            padding: '16px',
        },
        '& span.MuiTypography-body1': {
            fontFamily: 'Lato',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '14px',
            lineHeight: '20px',
            display: 'flex',
            alignItems: 'flex-end',
            letterSpacing: '0.25px',
            color: COLORS.darkGrey,
        },
        '& fieldset div  p': {
            fontFamily: 'Lato',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '14px',
            lineHeight: '20px',
            letterSpacing: '0.25px',
            color: COLORS.lightGrey,
            opacity: '0.6',
            margin: '0 0 0 35px'
        },
    },
    dialogContent: {
        padding: 0,
        '& span.material-icons': {
            marginRight: '20px'
        },
    },
});

export interface TariffPlanActionProps {
    open: boolean;
    selectedValue: string;
    onClose: (value: any) => void;
}

export default function TariffPlanAction(props: TariffPlanActionProps) {
    const classes = useStyles();
    const { onClose, selectedValue, open } = props;

    const handleClose = () => {
        onClose(selectedValue);
    };

    const handleListItemClick = (value: string) => {
        onClose(value);
    };

    const [value, setValue] = React.useState(data.tariffPlansType[0].name);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue((event.target as HTMLInputElement).value);
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            className={classes.root}
        >
            <span style={styles.title}>
                <h3 style={styles.h3}>Тарифный план</h3>
                <IconButton aria-label='delete' onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </span>
            <DialogContent className={classes.dialogContent}>
                {data.tariffPlansType.map((item) => (
                    <FormControl component='fieldset' key={item.id} >
                        <RadioGroup
                            name='tariffPlan'
                            value={value}
                            onChange={handleChange}
                            onClick={() => handleListItemClick(item.label)}
                        >
                            <FormControlLabel
                                value={item.name}
                                control={<RedRadio />}
                                color='secondary'
                                label={item.name} />
                            <p>{item.subLabel}</p>
                        </RadioGroup>
                    </FormControl>
                ))}
            </DialogContent>
        </Dialog>
    );
}
