import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import { styles, COLORS } from '../../../../styles';

const useStyles = makeStyles({
  root: {
    borderRadius: '8px',
    marginTop: '16px',
    boxShadow: '0px 12px 20px rgb(156 21 21 / 7%)',
    height: 'fit-content',
    background: COLORS.white,
    '&:hover': {
      background: COLORS.lightGreyTransparent,
    },
  },
});

export default function ParticipantListItem({ item }: any) {
  const classes = useStyles();
  return (
    <div className={classes.root} >
      <ListItem >
        <ListItemAvatar style={styles.avatar}>
          <Avatar >
            <img
              alt={item.name}
              src={item.image}
            />
          </Avatar>
        </ListItemAvatar>
          <ListItemText primary={item.name} secondary={item.description} />
      </ListItem>
    </div>
  );
};
