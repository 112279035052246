import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useForm } from 'react-hook-form';

import { Context } from '../../../../context';
import { editDescriptionGroup } from '../../../../actions';
import { COLORS, styles } from '../../../../styles';


const useStyles = makeStyles({
    root: {
        '& div.MuiDialog-paper': {
            padding: '16px',
        },
        "& span.material-icons": {
            marginRight: "20px"
        },
        "& div.MuiDialog-paperWidthSm": {
            minHeight: 335
        },
    },
    dialogContent: {
        padding: 0,
    },
    iconButton: {
        padding: '8px',
        color: COLORS.lightGrey,
    },
    button: {
        marginTop: "24px",
        display: "flex",
        justifyContent: "end",
        '& button': {
            color: COLORS.red
        }
    },
    listItem: {
        padding: 0,
        marginBottom: '30px'
    },
    form: {
        marginTop: '10px',
        width: '100%',
        '& div.MuiSelect-outlined': {
            textAlign: 'start'
        },
    }
});

export default function DescriptionAction() {
    const classes = useStyles();
    const [state, setState] = React.useState(false);
    const history = useHistory();
    const { state: { currentGroup }, dispatch } = useContext(Context);

    const handleClickOpen = () => {
        setState(true);
    };

    const handleClose = () => {
        setState(false);
    };

    const { register, handleSubmit } = useForm({
        defaultValues: {
            description: currentGroup.description,
        }
    });

    const submitHandler = handleSubmit((data: any) => {
        dispatch(
            editDescriptionGroup({ ...currentGroup, description: data.description })
        );
        history.push('/groupSettings');
        handleClose();
    });

    return (
        <div>
            <IconButton onClick={handleClickOpen} className={classes.iconButton}>
                <MoreVertIcon />
            </IconButton>

            <Dialog
                fullWidth
                maxWidth="sm"
                open={state}
                onClose={handleClose}
                className={classes.root}
            >
                <span style={styles.title}>
                    <h3 style={styles.h3}>Редактировать</h3>
                    <IconButton aria-label="delete" onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </span>
                <DialogContent className={classes.dialogContent}>
                    <form className={classes.form} onSubmit={submitHandler}>
                        <TextField
                            fullWidth
                            label='Описание'
                            variant='outlined'
                            multiline
                            minRows={10}
                            maxRows={10}
                            size='small'
                            {...register('description')}
                        />
                        <span className={classes.button}>
                            <Button  color="primary" type='submit'>
                                Сохранить
                            </Button>
                        </span>
                    </form>
                </DialogContent>
            </Dialog>
        </div>
    );
}
