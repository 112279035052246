import { IconButton, makeStyles } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListSubheader from '@material-ui/core/ListSubheader';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import { Link } from 'react-router-dom';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import { COLORS, styles } from '../../styles';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        padding: '80px 16px 0 16px',
    },
    content: {
        height: '70vh',
        overflowY: 'auto',
        paddingTop: '60px',
        '& ul:last-child': {
            marginBottom: '50px',
        },
    },
    card: {
        borderRadius: '8px',
        marginTop: '16px',
        background: COLORS.white,
        boxShadow: '0px 12px 20px rgba(156, 21, 21, 0.18)',
        height: '80px',
        '&:hover': {
            background: COLORS.lightGreyTransparent,
        },
    },
    subHeader: {
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '24px',
        letterSpacing: '0.15px',
        color: COLORS.lightGrey,

        '&.MuiListSubheader-gutters': {
            padding: '0'
        }
    },
    actionCard: {
        height: '100%',
        justifyContent: 'space-around',
        '& span:nth-child(2n)': {
            justifyContent: 'end'
        },
    },
    icon: {
        color: COLORS.lightGrey,
        '&.MuiIconButton-sizeSmall.MuiIconButton-edgeStart': {
            marginLeft: '16px',
        }
    },
    footer: {
        justifyContent: 'end'
    }
});

const items = {
    groupName: 'Школа №18, класс 1”А”',
    message: [
        {
            id: 1,
            name: 'Ирина',
            description: 'ДД! Подскажите, пожалуйста, какой цв...',
            img: '/assets/slot.png',
            route: '',
            action: 99,
            time: '26.07.2021'
        },
        {
            id: 2,
            name: 'Иван Иванов',
            img: '/assets/slot.png',
            description: 'ДД! Подскажите, пожалуйста, какой цв...',
            route: '',
            action: 99,
            time: '26.07.2021'
        },
    ]

};

export default function MessageList() {
    const classes = useStyles();
    // const [messages] = useCollectionData(
    //     db.collection("messages")
    //     .where("uid", "==", user.id)
    // )
    
    return (
        <>
            <Header title='Назад' />

            <div className={classes.root}>
                <span style={styles.blurTitle}>
                    <h3 style={styles.h3}>Сообщения</h3>
                </span>
                <div className={classes.content}>
                    <List
                        subheader={
                            <ListSubheader
                                component='div'
                                className={classes.subHeader}
                                style={{ textAlign: 'start' }}
                                disableSticky
                            >
                                {items.groupName}
                            </ListSubheader>
                        }>
                        {items.message.map(item => (
                            <Link
                                key={item.id}
                                to='/chat'
                                style={styles.link}
                            >
                                <ListItem className={classes.card} key={item.id}>
                                    <ListItemAvatar style={styles.avatar}>
                                        <Avatar >
                                            <img
                                                alt=''
                                                src={item.img}
                                            />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={item.name}
                                        secondary={item.description}
                                    />
                                    <div style={styles.col} className={classes.actionCard}>
                                        <span style={(styles.row, styles.secondaryText)}>
                                            {item.time}
                                        </span>
                                        <span style={styles.row}>
                                            {item.action && item.action > 0 ?
                                                <Chip style={styles.chip} label={item.action} /> : null}
                                        </span>
                                    </div>
                                </ListItem>
                            </Link>

                        ))}
                        <div style={styles.row} className={classes.footer} >
                            <p className={classes.subHeader}>Смотреть все чаты</p>
                            <IconButton
                                edge='start'
                                size='small'
                                className={classes.icon}
                            >
                                <ArrowForwardIosIcon fontSize='inherit' />
                            </IconButton>
                        </div>
                    </List>
                </div>
            </div>

            <Footer route='/newMessage' />
        </>
    );
}
