import {
  Action,
  Group,
  ActionType,
  User,
  Task,
  Participant,
  GroupEvent,
} from '../interface';


export const addGroup = (group: Group): Action => ({
  type: ActionType.ADD_GROUP,
  payload: group,
});

export const deleteGroup = (id: number): Action => ({
  type: ActionType.DELETE_GROUP,
  payload: id,
});

export const editDescriptionGroup = (group: Group): Action => ({
  type: ActionType.EDIT_DES_GROUP,
  payload: group,
});

export const checkUser = (user: User): Action => ({
  type: ActionType.CHECK_USER,
  payload: user,
});

export const editUserProfile = (user: User): Action => ({
  type: ActionType.EDIT_USER_PROFILE,
  payload: user,
});

export const completeTask = (id: number): Action => ({
  type: ActionType.COMPLETE_TASK,
  payload: id,
});

export const addTask = (task: Task): Action => ({
  type: ActionType.ADD_TASK,
  payload: task,
});

export const displayGroupEvent = (item: GroupEvent): Action => ({
  type: ActionType.DISPLAY_GROUP_EVENT,
  payload: item,
});

export const enableNotification = (item: boolean): Action => ({
  type: ActionType.ENABLE_NOTIFICATION,
  payload: item,
});

export const pinGroup = (item: boolean): Action => ({
  type: ActionType.PIN_GROUP,
  payload: item,
});

export const addParticipant = (participant: Participant): Action => ({
  type: ActionType.ADD_PARTICIPANT,
  payload: participant,
});

export const deleteParticipant = (item: number[]): Action => ({
  type: ActionType.DELETE_PARTICIPANT,
  payload: item,
});

export const selectCurrentGroup = (group: Group): Action => ({
  type: ActionType.CURRENT_GROUP,
  payload: group,
});
