import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useHistory } from 'react-router-dom';

import { COLORS, GreyButton, RedButton, RedCheckbox, styles } from "../../../../styles";
import Header from '../../../Header/Header';
import { Context } from 'src/context';
import { deleteParticipant } from 'src/actions';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    padding: '80px 16px 0 16px',
  },
  card: {
    borderRadius: '8px',
    marginTop: '16px',
    boxShadow: '0px 12px 20px rgb(156 21 21 / 7%)',
    height: 'fit-content',
    background: COLORS.white,
    '&:hover': {
      background: COLORS.lightGreyTransparent,
    },
  },
  groupList: {
    height: 'calc(100vh - 250px)',
    overflowY: 'auto',
    boxShadow: 'none',
    paddingTop: '54px',
    paddingBottom: '130px',
  },
  footer: {
    display: "flex",
    flexDirection: "row",
    position: 'fixed',
    zIndex: 999,
    backdropFilter: 'blur(1px)',
    flexGrow: 1,
    boxShadow: 'none',
    justifyContent: "space-around",
    width: '100%',
    bottom: 0,
    height: '130px',
    alignItems: "center",
    background: "linear-gradient(180deg, rgba(0, 0, 0, 0) 10.4%, rgb(40 23 20 / 38%) 100%)",
    margin: '-16px'
  },
  button: {
    marginTop: '30px',
  },
});

export default function DeleteParticipant() {
  const classes = useStyles();
  const history = useHistory();
  const { state: { participant }, dispatch } = useContext(Context);

  let state: number[] = [];
  const [checked, setChecked] = React.useState(state);
  const hidden = checked.length > 0 ? 'visible' : 'hidden';

  const handleCheckboxChange = (position: number) => {
    let newArray = [...checked, position];
    if (checked.includes(position)) {
      newArray = newArray.filter(el => el !== position);
    }
    setChecked(newArray);
  };

  const deleteHandler = () => {
    dispatch(deleteParticipant(checked));
    history.push('/groupSettings', 2)
  };

  return (
    <>
      <Header title='Удалить участника' />

      <div className={classes.root}>
        <span style={styles.blurTitle}>
          <h3 style={styles.h3}>Участники</h3>
        </span>
        <div className={classes.groupList}>
          {participant.map((item, index) => (
            <ListItem key={item.id} className={classes.card}>
              <FormControlLabel
                control={
                  <RedCheckbox
                    onChange={() => handleCheckboxChange(index)}
                    name={item.name}
                  />
                }
                label=''
              />
              <ListItemAvatar style={styles.avatar}>
                <Avatar >
                  <img
                    alt={item.name}
                    src={item.image}
                  />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={item.name} secondary={item.description} />
            </ListItem>
          ))}
        </div>
        <div className={classes.footer} style={{ visibility: hidden }}>
          <span style={{ width: '156px' }}>
            <GreyButton onClick={() => { history.goBack() }}>
              Отмена
            </GreyButton>
          </span>
          <span style={{ width: '156px' }}>
            <RedButton onClick={deleteHandler}>
              Удалить
            </RedButton>
          </span>
        </div>
      </div>
    </>
  );
}
