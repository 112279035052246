import { makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';

import { groupTypes } from 'src/data';
import { RedButton, styles } from '../../styles';
import Header from '../Header/Header';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        padding: '80px 0 0 0',
    },
    listItem: {
        padding: 0,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    listItemCard: {
        width: "145px",
        height: "145px",
        margin: "16px",
        padding: "16px",
        background: "#FFFFFF",
        boxShadow: "0px 12px 20px rgb(156 21 21 / 18%)",
        borderRadius: "8px",
        justifyContent: "space-between",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        '& div.MuiListItemText-root': {
            flex: 0
        },
        '&.MuiListItem-button:hover': {
            backgroundColor: "#cbcbcb",
        },
    },
    mL:{
        marginLeft:16
    }
});

export default function NewGroupCopy() {
    const classes = useStyles();
    const history = useHistory();
    const [typeId, setTypeId] = useState(1);

    const checkGroupType = (typeId: number) => {
        setTypeId(typeId)
    }

    const submitHandler = () => {
        switch (typeId) {
            case 1: {
                console.log(typeId);
                history.push('/createNewParentCommitteeGroup')
                break;
            }
            case 2: {
                console.log(typeId);
                history.push('#')
                break;
            }
            case 3: {
                console.log(typeId);
                history.push('#')
                break;
            }
            case 4: {
                console.log(typeId);
                history.push('#')
                break;
            }
            default: {
                history.push('#')
                break;
            }
        }
    };

    return (
        <>
            <Header title='Создать новую группу' />
            <div className={classes.root}>
                <span style={styles.title} className={classes.mL}>
                    <h3 style={styles.h3}>Выберите тип группы</h3>
                </span>
                <List className={classes.listItem}>
                    {groupTypes.map((item) => (
                        <ListItem
                            button
                            className={classes.listItemCard}
                            key={item.id}
                            onClick={() => checkGroupType(item.id)}
                        >
                            <ListItemAvatar >
                                <img
                                    alt={item.name}
                                    src={item.image}
                                    style={{ minWidth: 50 }}
                                />
                            </ListItemAvatar>
                            <ListItemText
                                primary={item.name}
                            />
                        </ListItem>
                    ))}
                </List>
                <span >
                    <RedButton type='submit' onClick={submitHandler}>
                        Продолжить
                    </RedButton>
                </span>
            </div>
        </>
    );
}
