import Charts from "./components/Chart/Chart";
import Desktop from "./components/Desktop";
import ProfileSetting from "./components/Footer/ProfileSetting";
import AddParticipantInGroup from "./components/Group/AddParticipantInGroup";
import CreateNewParentCommitteeGroup from "./components/Group/CreateNewParentCommitteeGroup";
import GroupList from "./components/Group/GroupList";
import GroupSettings from "./components/Group/GroupSettings/GroupSettings";
import AddParticipant from "./components/Group/GroupSettings/Participant/AddParticipant";
import DeleteParticipant from "./components/Group/GroupSettings/Participant/DeleteParticipant";
import RequestParticipant from "./components/Group/GroupSettings/Participant/RequestParticipant";
import SearchParticipant from "./components/Group/GroupSettings/Participant/SearchParticipant";
import NewGroup from "./components/Group/NewGroup";
import NewGroupCopy from "./components/Group/NewGroupCopy";
import Chat from "./components/Message/Chat";
import MessageList from "./components/Message/MessageList";
import NewMessage from "./components/Message/NewMessage";
import Search from "./components/Search/Search";
import SearchResult from "./components/Search/SearchResult";
import NewTask from "./components/Task/NewTask";
import NewTaskCopy from "./components/Task/NewTaskCopy";
import NewShopping from "./components/Task/Shopping/NewShopping";
import ShoppingListDetails from "./components/Task/Shopping/ShoppingListDetails";
import TaskList from "./components/Task/TaskList";
import NewVote from "./components/Task/Vote/NewVote";
import NewVoteCopy from "./components/Task/Vote/NewVoteCopy";
import VoteListDetails from "./components/Task/Vote/VoteListDetails";

import Login from "./pages/Login";
import Register from "./pages/Register";
import StartPage from "./pages/StartPage";

const routes = [
    { path: '/', component: StartPage },

    { path: '/login', component: Login },
    { path: '/register', component: Register },

    { path: '/groupList', component: GroupList },
    { path: '/desktop', component: Desktop },

    { path: '/newGroup', component: NewGroup },
    { path: '/newGroupCopy', component: NewGroupCopy },
    { path: '/createNewParentCommitteeGroup', component: CreateNewParentCommitteeGroup },
    { path: '/groupSettings', component: GroupSettings },
    { path: '/groupSettings/:activeTab', component: GroupSettings },

    { path: '/addParticipant', component: AddParticipant },
    { path: '/addParticipantInGroup', component: AddParticipantInGroup },
    { path: '/deleteParticipant', component: DeleteParticipant },
    { path: '/searchParticipant', component: SearchParticipant },
    { path: '/requestParticipant', component: RequestParticipant },

    { path: '/newTask', component: NewTask },
    { path: '/newTaskCopy', component: NewTaskCopy },
    { path: '/taskList', component: TaskList },

    { path: '/voteListDetails/:id', component: VoteListDetails },
    { path: '/voteListDetails', component: VoteListDetails },
    { path: '/newVote', component: NewVote },
    { path: '/newVoteCopy', component: NewVoteCopy },

    { path: '/shoppingListDetails/:id', component: ShoppingListDetails },
    { path: '/shoppingListDetails', component: ShoppingListDetails },
    { path: '/newShopping', component: NewShopping },

    { path: '/search', component: Search },
    { path: '/searchResult', component: SearchResult },

    { path: '/messageList', component: MessageList },
    { path: '/newMessage', component: NewMessage },
    { path: '/chat', component: Chat },

    { path: '/chart', component: Charts },

    { path: '/profileSetting', component: ProfileSetting },

    
];

export  default routes;