import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import Chip from '@material-ui/core/Chip';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from '@material-ui/core/Icon';
import Switch from "@material-ui/core/Switch";
import CloseIcon from '@material-ui/icons/Close';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Link } from 'react-router-dom';

import { COLORS, styles } from "../../styles";
import { deleteGroup, enableNotification, pinGroup } from '../../actions';
import { Context } from '../../context';
import { Group, Task } from '../../interface';

const useStyles = makeStyles({
    root: {
        '& div.MuiDialog-paper': {
            padding: '16px',
        },
        '& span.MuiTypography-root.MuiListItemText-primary.MuiTypography-body1.MuiTypography-displayBlock': {
            fontFamily: "Lato",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "14px",
            lineHeight: "20px",
            letterSpacing: "0.1px",
            color: COLORS.darkGrey,
        },
    },
    dialogContent: {
        padding: 0,
        "& span.material-icons": {
            marginRight: "20px"
        },
    },
    switch: {
        color: COLORS.red,
        '& span.MuiSwitch-colorSecondary.Mui-checked': {
            color: COLORS.red,
        },
        '& span.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
            backgroundColor: COLORS.red,
        },
    },
    button: {
        padding: '8px'
    }
});

export default function GroupAction(item: Group) {
    const classes = useStyles();
    const { state:
        {
            groupEvent,
            isNotification,
            isAnchor,
            task,
            currentGroup
        },
        dispatch } = useContext(Context);

    const countOfTasks = task.filter((item: Task) => item.groupId === currentGroup.id).length;

    const [state, setState] = React.useState({
        open: false,
        isNotification: isNotification,
        isAnchor: isAnchor,
    });

    const groupEvents = groupEvent.filter((item) => item.viewInPreviewGroup === true);

    const handleClickOpen = () => {
        setState({ ...state, open: true });
    };

    const handleClose = () => {
        setState({ ...state, open: false });
    };

    const isNotificationHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState({ ...state, isNotification: event.target.checked });
        dispatch(enableNotification(event.target.checked));
    };

    const isAnchorHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState({ ...state, isAnchor: event.target.checked });
        dispatch(pinGroup(state.isAnchor
        ));
    };

    // const deleteHandler = (id: any) => {
    //     dispatch(deleteGroup(id));
    //     handleClose();
    // };

    return (
        <div>
            <IconButton onClick={handleClickOpen} className={classes.button}>
                <MoreVertIcon />
            </IconButton>

            <Dialog
                open={state.open}
                onClose={handleClose}
                className={classes.root}
            >
                <span style={styles.title}>
                    <h3 style={styles.h3}>Действия в группе</h3>
                    <IconButton aria-label="delete" onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </span>
                <DialogContent className={classes.dialogContent}>
                    <List>
                        {groupEvents.map((item) => (
                            <Link
                                key={item.id}
                                style={styles.link}
                                to={{
                                    pathname: item.route,
                                    state: item
                                }}
                            >
                                <ListItem button key={item.id}>
                                    <Icon >
                                        <img src={item.image} alt={item.name} />
                                    </Icon>
                                    <ListItemText
                                        primary={item.name}
                                    />
                                    {item.action && item.action > 0 ?
                                        <Chip style={styles.chip} label={item.action} /> : null}
                                    {item.id === 2 &&
                                        <Chip style={styles.chip} label={countOfTasks} />}
                                </ListItem>
                            </Link>
                        ))}

                        <ListItem button>
                            <ListItemText primary="Включить уведомления" />
                            <Switch
                                onChange={isNotificationHandleChange}
                                checked={state.isNotification}
                                name="isNotification"
                                className={classes.switch}
                            />
                        </ListItem>

                        <ListItem button>
                            <ListItemText primary="Закрепить группу" />
                            <Switch
                                onChange={isAnchorHandleChange}
                                checked={state.isAnchor}
                                name="isAnchor"
                                className={classes.switch}
                            />
                        </ListItem>

                        {/* <ListItem button >
                            <ListItemText primary="Ссылка на группу" />
                        </ListItem> */}

                        <Link
                            style={styles.link}
                            to='/addParticipantInGroup'
                        >
                            <ListItem button >
                                <ListItemText primary="Ссылка на группу" />
                            </ListItem>
                        </Link>
                    </List>
                </DialogContent>
            </Dialog>
        </div>
    );
}
