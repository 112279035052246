import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';

import { COLORS } from '../../../styles';
import ParticipantList from './Participant/ParticipantList';
import Setting from './Setting';
import Description from './Description';
import Header from '../../Header/Header';
import { Context } from 'src/context';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        padding: '80px 16px 0 16px',
    },
    tabContext: {
        '& header.MuiAppBar-colorPrimary': {
            background: '#E0E4E9',
            boxShadow: '0px 16px 27px rgba(149, 166, 183, 0.36)',
            borderRadius: '25px'
        },
        '& div.MuiTabs-flexContainer': {
            justifyContent: 'space-around'
        },
        '& span.MuiTab-wrapper': {
            color: COLORS.darkGrey,
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: '600',
            fontSize: '12px',
            lineHeight: '120%',
            display: 'flex',
            alignItems: 'center',
            textAlign: 'center',
            textTransform: 'none'
        },
        '& button.Mui-selected span.MuiTab-wrapper': {
            color: COLORS.white,
            background: COLORS.lightGrey,
            borderRadius: '24px',
            height: '30px',
            padding: '0 16px'
        },
        '& span.PrivateTabIndicator-colorSecondary-32': {
            backgroundColor: 'transparent'
        },
        '& div.MuiTabPanel-root': {
            padding: 0
        },
    },
    appBar:{
        marginTop: 20,
    }
});

export default function GroupSettings(props?:any) {
    const classes = useStyles();
    const activeTab = props.location.state? ('' + props.location.state) : '1';
    const [value, setValue] = React.useState(activeTab);
    const { state: { currentGroup } } = useContext(Context);
    
    const handleChange = (event: any, newValue: any) => {
        setValue(newValue);
    };

    return (
        <>
            <Header title={currentGroup.name}/>
            <div className={classes.root} >
                <div className={classes.tabContext}>
                    <TabContext value={value} >
                        <AppBar position='static' className={classes.appBar}>
                            <TabList onChange={handleChange} TabIndicatorProps={{ style: { backgroundColor: "transparent" } }}>
                                <Tab label='Описание' value='1' />
                                <Tab label='Участники' value='2' />
                                <Tab label='Настройки' value='3' />
                            </TabList>
                        </AppBar>
                        <TabPanel value='1' >
                            <Description/>
                        </TabPanel>
                        <TabPanel value='2' >
                            <ParticipantList />
                        </TabPanel>
                        <TabPanel value='3' >
                            <Setting />
                        </TabPanel>
                    </TabContext>
                </div>
            </div>
        </>
    );
}
