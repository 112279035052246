
import { useContext } from 'react';
import { useForm, useFormState } from 'react-hook-form';
import { useHistory } from 'react-router';
import { Context } from 'src/context';
import { makeStyles } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import TextField from '@material-ui/core/TextField';
import ListItemText from '@material-ui/core/ListItemText';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import MailOutlinedIcon from '@material-ui/icons/MailOutlined';

import Header from '../Header/Header';
import { COLORS, GreyButton, RedButton } from 'src/styles';
import { editUserProfile } from 'src/actions';
import './Footer.scss';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        padding: '80px 16px 0 16px',
    },
    iconButton: {
        padding: '8px',
        color: COLORS.lightGrey,
    },
    footer: {
        display: "flex",
        flexDirection: "row",
        position: 'fixed',
        zIndex: 999,
        backdropFilter: 'blur(1px)',
        flexGrow: 1,
        boxShadow: 'none',
        justifyContent: "space-around",
        width: '100%',
        bottom: 0,
        height: '130px',
        alignItems: "center",
        background: "linear-gradient(180deg, rgba(0, 0, 0, 0) 10.4%, rgb(40 23 20 / 38%) 100%)",
        margin: '-16px'
    },
    button: {
        marginTop: '30px',
    },
});

export default function ProfileSetting() {
    const classes = useStyles();
    const history = useHistory();
    const { state: { user }, dispatch, db } = useContext(Context);

    const { register, handleSubmit, control } = useForm({
        defaultValues: {
            login: user.login,
            phone: user.phone,
            email: user.email,
        }
    });

    const { dirtyFields } = useFormState({ control });
    const submitHandler = handleSubmit((data: any) => {
        db.collection('users').doc(user.docId).update({
            displayName: data.login,
            phoneNumber: data.phone,
            email: data.email,
        });
        dispatch(
            editUserProfile({
                id: user.id,
                login: data.login,
                password: user.password,
                image: user.image,
                phone: data.phone,
                email: data.email,
            }))
        history.push('/groupList');
    });

    return (
        <div className={classes.root}>
            <Header title='Настройки профиля' />
            <form className='form' onSubmit={submitHandler}>
                <List>
                    <ListItem button className='listItem' style={{ marginBottom: '20px' }}>
                        <ListItemIcon >
                            <PersonOutlineOutlinedIcon />
                        </ListItemIcon>
                        <TextField
                            fullWidth
                            label="Логин"
                            size='small'
                            {...register('login')}
                        />
                    </ListItem>

                    <ListItem button className='listItem' style={{ marginBottom: '20px' }}>
                        <ListItemIcon >
                            <PhoneOutlinedIcon />
                        </ListItemIcon>
                        <TextField
                            fullWidth
                            label="Номер телефона"
                            size='small'
                            {...register('phone')}
                        />
                    </ListItem>

                    <ListItem button className='listItem' style={{ marginBottom: '20px' }}>
                        <ListItemIcon >
                            <MailOutlinedIcon />
                        </ListItemIcon>
                        <TextField
                            fullWidth
                            label="E-mail"
                            size='small'
                            {...register('email')}
                        />
                    </ListItem>

                    <ListItem button className='listItem' style={{ marginBottom: '20px' }}>
                        <ListItemIcon >
                            <ImageOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary='Фото профиля' />
                    </ListItem>
                </List>
                {dirtyFields.login || dirtyFields.phone || dirtyFields.email ? (
                    <div className={classes.footer}>
                        <span style={{ width: '156px' }}>
                            <GreyButton onClick={() => { history.goBack() }}>
                                Отмена
                            </GreyButton>
                        </span>
                        <span style={{ width: '156px' }}>
                            <RedButton type='submit' onClick={submitHandler}>
                                Сохранить
                            </RedButton>
                        </span>
                    </div>
                ) : null}
            </form>
        </div>
    );
}
