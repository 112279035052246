import { Link } from 'react-router-dom';

import { styles, RedButton, GreyButton } from '../../styles';
import './index.scss';

export default function StartPage() {
    return (
        <div className='startPageContent'>
            <div className='logo'>
                <span>
                    <p>Pay box</p>
                </span>
            </div>
            <div className='form' >
                    <h3 style={styles.h3}>Добро пожаловать</h3>
                <Link to='/login' style={styles.link}>
                    <RedButton>
                        Вход
                    </RedButton>
                </Link>
                <Link to='/register' style={styles.link}>
                    <GreyButton>
                        Регистрация
                    </GreyButton>
                </Link>
            </div>
        </div >
    );
}
