import { makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';

import { GreyButton, RedButton, styles } from '../../../styles';
import Header from '../../Header/Header';
import { useContext } from 'react';
import { Context } from 'src/context';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        padding: '80px 16px 0 16px',
    },
    button: {
        width: '100%',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between'
    },
    form: {
        '& span:first-child': {
            marginTop: 0,
        },
        '& span': {
            marginTop: '30px',
        },
    },
    field: {
        '& div.MuiSelect-outlined': {
            textAlign: 'start'
        },
    }
});

export default function NewShopping() {
    const { state: { currentGroup } } = useContext(Context);
    const classes = useStyles();
    const history = useHistory();

    return (
        <>
            <Header title={currentGroup.name} />
            <div className={classes.root}>
                <span style={styles.title}>
                    <h3 style={styles.h3}>Создание покупок</h3>
                </span>
                <div className={classes.form}>
                    <span className={classes.row}>
                        <TextField
                            label='Введите описание'
                            helperText='Описание покупки'
                            variant='outlined'
                            size='small'
                            className={classes.field}
                            style={{ width: '60%' }}
                        />
                        <TextField
                            label='0000.00'
                            helperText='Сумма'
                            variant='outlined'
                            size='small'
                            className={classes.field}
                            style={{ width: '35%' }}
                        />
                    </span>
                    <span className={classes.row}>
                        <TextField
                            label='Введите описание'
                            helperText='Описание покупки'
                            variant='outlined'
                            size='small'
                            className={classes.field}
                            style={{ width: '60%' }}
                        />
                        <TextField
                            label='0000.00'
                            helperText='Сумма'
                            variant='outlined'
                            size='small'
                            className={classes.field}
                            style={{ width: '35%' }}
                        />
                    </span>
                    <span className={classes.row}>
                        <TextField
                            label='Введите описание'
                            helperText='Описание покупки'
                            variant='outlined'
                            size='small'
                            className={classes.field}
                            style={{ width: '60%' }}
                        />
                        <TextField
                            label='0000.00'
                            helperText='Сумма'
                            variant='outlined'
                            size='small'
                            className={classes.field}
                            style={{ width: '35%' }}
                        />
                    </span>

                </div>
                <div className={classes.row}>
                    <span className={classes.button} style={{ width: '156px' }}>
                        <GreyButton onClick={() => { history.goBack() }}>
                            Отмена
                        </GreyButton>
                    </span>
                    <span className={classes.button} style={{ width: '156px' }} >
                        <RedButton onClick={() => { history.goBack() }}>
                            Создать
                        </RedButton>
                    </span>
                </div>
            </div>
        </>
    );
}
