import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import MailOutlinedIcon from '@material-ui/icons/MailOutlined';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';

// список пользователей
export const user = 
  {
    id: '1',
    login: 'demoUser',
    password: '123',
    image: '/assets/slot.png',
    phone: '+375 (29) 123 45 67',
    email: 'demoUser@gmail.com',
  };

// группы
export const groups = [
  {
    id: 1,
    name: 'Родительский комитет',
    image: '/assets/school.svg',
    description: 'Школа №18, класс 5 "Б".Нужды класса',
    action: 3,
  },
  {
    id: 2,
    name: 'ООО "Строй Монтаж"',
    image: '/assets/friends.svg',
    description: 'Корпоративы, дни рождения сотрудников и пр.',
    action: 1
  },
  {
    id: 3,
    name: 'Минск, ул.Новая, д.8 , подьезд №3',
    image: '/assets/neighbors.svg',
    description: 'Оперативное решение задач',
    action: 2
  },
  {
    id: 4,
    name: 'Семья',
    image: '/assets/family.svg',
    description: '...',
    action: 1
  },
];

// тип группы при создании
export const groupTypes = [
  {
    id: 1,
    name: 'Родительский комитет',
    image: '/assets/school.svg',
  },
  {
    id: 2,
    name: 'Друзья',
    image: '/assets/friends.svg',
  },
  {
    id: 3,
    name: 'Соседи',
    image: '/assets/neighbors.svg',
  },
  {
    id: 4,
    name: 'Семья',
    image: '/assets/family.svg',
  },
];

// тип задачи при создании
export const taskTypes = [
  {
    id: 1,
    name: 'Голосование',
    image: '/assets/scale_red.svg',
  },
  {
    id: 2,
    name: 'Оплата',
    image: '/assets/wallet_red.svg',
  },
  {
    id: 3,
    name: 'Покупки',
    image: '/assets/shoppingBag_red.svg',
  },
];

// назначенные задачи
export const tasks = [
  {
    id: 1,
    image: '/assets/scale.svg',
    route: '/voteListDetails',
    name: 'Голосование',
    description: 'Сбор средств для покупки дополнительной литературы. Для уроков английского необходим дополнительный пакет книг. 1 книга - 30 руб.',
    groupId: 1
  },
  {
    id: 3,
    name: 'Оплата',
    image: '/assets/shoppingBag.svg',
    route: '/shoppingListDetails',
    description: 'Сбор средств для покупки дополнительной литературы. Для уроков английского необходим дополнительный пакет книг. 1 книга - 30 руб.',
    groupId: 1,
    cash: '30 руб'
  },
  {
    id: 4,
    image: '/assets/scale.svg',
    route: '/voteListDetails',
    name: 'Голосование',
    description: 'Поход в музей. Белорусский государственный музей истории Великой Отечественной войны',
    groupId: 1
  },
  {
    id: 5,
    image: '/assets/scale.svg',
    route: '/voteListDetails',
    name: 'Голосование',
    description: 'Корпоратив ООО "Строй Монтаж". В следующую пятницу планируется корпоратив. Необходимо подтвертить, кто 100% будет присутствовать',
    groupId: 2
  },
  {
    id: 6,
    name: 'Оплата',
    image: '/assets/shoppingBag.svg',
    route: '/shoppingListDetails',
    description: 'Сбор средств для оплаты консьержа. Ежемесячный сбор',
    groupId: 3,
    cash: '50 руб'
  },
  {
    id: 7,
    name: 'Оплата',
    image: '/assets/shoppingBag.svg',
    route: '/shoppingListDetails',
    description: 'Сбор средств на облогораживание детской площадки. Планируется приобрести новый игровой комплекс',
    groupId: 3,
    cash: '100 руб'
  },
  {
    id: 8,
    name: 'Оплата',
    image: '/assets/shoppingBag.svg',
    route: '/shoppingListDetails',
    description: 'Квартира. Оплатить коммунальные услуги',
    groupId: 4,
    cash: '100 руб'
  },
];

//участники
export const participants = [
  {
    id: 1,
    name: 'Котова Виктория',
    image: '/assets/slot.png',
    description: 'родитель, член родительского коммитета',
  },
  {
    id: 2,
    name: 'Филатова Кристина',
    image: '/assets/slot.png',
    description: 'родитель, член родительского коммитета',
  },
  {
    id: 3,
    name: 'Лихачёв Тимур',
    image: '/assets/slot.png',
    description: 'родитель, член родительского коммитета',
  },
  {
    id: 4,
    name: 'Новиков Гавриил',
    image: '/assets/slot.png',
    description: 'родитель',
  },
  {
    id: 5,
    name: 'Михеев Иван',
    image: '/assets/slot.png',
    description: 'родитель',
  },
  {
    id: 6,
    name: 'Авдеева Мила',
    image: '/assets/slot.png',
    description: 'родитель',
  },
  {
    id: 7,
    name: 'Иван Иванов',
    image: '/assets/slot.png',
    description: 'родитель',
  },
  {
    id: 8,
    name: 'Котов Макар',
    image: '/assets/slot.png',
    description: 'родитель',
  },
  {
    id: 9,
    name: 'Новикова Милана',
    image: '/assets/slot.png',
    description: 'родитель',
  },
  {
    id: 10,
    name: 'Попова Вероника',
    image: '/assets/slot.png',
    description: 'родитель',
  },
];

export const newParticipants = [
  {
    id: 1,
    name: 'Казаков Артем',
    image: '/assets/slot.png',
  },
  {
    id: 2,
    name: 'Комаров Виталий',
    image: '/assets/slot.png',
  },
];

//меню - настройки профиля
export const linkItemProfile = [
  {
    id: 1,
    name: 'Имя',
    nameEn: 'name',
    route: '#',
    image: <PersonOutlineOutlinedIcon />
  },
  {
    id: 2,
    name: 'Фото профиля',
    nameEn: 'photo',
    route: '#',
    image: <ImageOutlinedIcon />
  },
  {
    id: 3,
    name: 'Номер телефона',
    nameEn: 'phone',
    route: '#',
    image: <PhoneOutlinedIcon />
  },
  {
    id: 4,
    name: 'E-mail',
    nameEn: 'E-mail',
    route: '#',
    image: <MailOutlinedIcon />
  },
  // {
  //   id: 5,
  //   name: 'Мои карты',
  //   nameEn: 'creditCard',
  //   route: '#',
  //   image: <CreditCardOutlinedIcon />
  // },
];

//меню - Вход/выход
export const linkItemLogin = [
  {
    id: 1,
    name: 'Выйти',
    nameEn: 'logout',
    route: '/login',
    image: <ExitToAppOutlinedIcon />
  },
  {
    id: 2,
    name: 'Войти в другой аккаунт',
    nameEn: 'logout',
    route: '/login',
    image: <PersonAddOutlinedIcon />
  },

];

// настройки группы(отображать на рабочем столе и/или предпросмотр)
export const groupEvents = [
  {
    id: 1,
    name: 'Бюджет',
    description: '',
    image: '/assets/cash.svg',
    action: 150,
    route: '',
    viewInDesktop: true,
    viewInPreviewGroup: false
  },
  {
    id: 2,
    name: 'Задачи',
    description: '',
    image: '/assets/task.svg',
    route: '/taskList',
    viewInDesktop: true,
    viewInPreviewGroup: true
  },
  {
    id: 3,
    name: 'Сообщения',
    description: '',
    image: '/assets/messageGrey.svg',
    route: '/messageList',
    viewInDesktop: true,
    viewInPreviewGroup: true
  },
  {
    id: 4,
    name: 'Отчет',
    description: '',
    image: '/assets/report.svg',
    route: '/chart',
    viewInDesktop: true,
    viewInPreviewGroup: false
  },
];

// вид тарифа
export const tariffPlansType = [
  {
    id: 1,
    name: 'Free - бесплатно',
    label: 'Free',
    subLabel: 'до 30 участников, до 10 задач, до 10 голосований',
  },
  {
    id: 2,
    name: 'Standard  - USD 1,99 в месяц',
    label: 'Standard',
    subLabel: 'до 60 участников, до 30 задач, до 30 голосований',
  },
  {
    id: 3,
    name: 'Premium - USD 2,99 в месяц',
    label: 'Premium',
    subLabel: '80 более участников, неограниченное количество задач и голосований',
  },
];

// чат
export const messages = [
  {
    message: 'Lorem Ipsum - это текст-рыба, часто используемый в печати и вэб-дизайне.',
    time: '9.05.2021, 16.04',
    direction: 'left'
  },
  {
    message: 'Lorem Ipsum является стандартной рыбой для текстов на латинице с начала XVI века.',
    time: '11.05.2021, 16.05',
    direction: 'right'
  },
  {
    message: 'В то время некий безымянный печатник создал большую коллекцию размеров и форм шрифтов, используя Lorem Ipsum для распечатки образцов. ',
    time: '9.05.2021, 16.06',
    direction: 'right'
  },
  {
    message: 'Его популяризации в новое время послужили публикация листов Letraset с образцами Lorem Ipsum в 60-х годах и, в более недавнее время, ',
    time: '9.05.2021, 16.08',
    direction: 'left'
  },
  {
    message: 'программы электронной вёрстки типа Aldus PageMaker,',
    time: '9.05.2021, 16.09',
    direction: 'right'
  },
  {
    message: ' в шаблонах которых используется Lorem Ipsum.',
    time: '9.05.2021, 16.10',
    direction: 'left'
  },
  {
    message: 'Lorem Ipsum - это текст-рыба, часто используемый в печати и вэб-дизайне.',
    time: '9.05.2021, 16.04',
    direction: 'right'
  },
  {
    message: 'Lorem Ipsum является стандартной рыбой для текстов на латинице с начала XVI века.',
    time: '11.05.2021, 16.05',
    direction: 'right'
  },
  {
    message: 'В то время некий безымянный печатник создал большую коллекцию размеров и форм шрифтов, используя Lorem Ipsum для распечатки образцов. ',
    time: '9.05.2021, 16.06',
    direction: 'right'
  },
  {
    message: 'Его популяризации в новое время послужили публикация листов Letraset с образцами Lorem Ipsum в 60-х годах и, в более недавнее время, ',
    time: '9.05.2021, 16.08',
    direction: 'left'
  },
  {
    message: 'программы электронной вёрстки типа Aldus PageMaker,',
    time: '9.05.2021, 16.09',
    direction: 'left'
  },
  {
    message: ' в шаблонах которых используется Lorem Ipsum.',
    time: '9.05.2021, 16.10',
    direction: 'right'
  },
  {
    message: 'Lorem Ipsum - это текст-рыба, часто используемый в печати и вэб-дизайне.',
    time: '9.05.2021, 16.04',
    direction: 'left'
  },
  {
    message: 'Lorem Ipsum является стандартной рыбой для текстов на латинице с начала XVI века.',
    time: '11.05.2021, 16.05',
    direction: 'right'
  },
  {
    message: 'В то время некий безымянный печатник создал большую коллекцию размеров и форм шрифтов, используя Lorem Ipsum для распечатки образцов. ',
    time: '9.05.2021, 16.06',
    direction: 'right'
  },
  {
    message: 'Его популяризации в новое время послужили публикация листов Letraset с образцами Lorem Ipsum в 60-х годах и, в более недавнее время, ',
    time: '9.05.2021, 16.08',
    direction: 'left'
  },
  {
    message: 'программы электронной вёрстки типа Aldus PageMaker,',
    time: '9.05.2021, 16.09',
    direction: 'left'
  },
  {
    message: ' в шаблонах которых используется Lorem Ipsum.',
    time: '9.05.2021, 16.10',
    direction: 'right'
  },
];

// результат поиска
export const searchResultGroup = [
  {
    id: 1,
    name: 'Родительский комитет ',
    image: '/assets/slot.png',
    description: 'Школа №18, класс 1”А”. Нужды класса',
    route: ''
  },
];

// список покупок
export const shoppingListItems = [
  {
    id: 1,
    name: 'Дополнительная литература',
    description: '30 руб.',
    route: '/shoppingListDetails'
  },
  {
    id: 2,
    name: 'Новые занавески',
    description: '12 руб.',
    route: ''
  },
  {
    id: 3,
    name: 'Дополнительное питание',
    description: '10 руб.',
    route: ''
  },
];

// список голосований
export const voteListItems = [
  {
    id: 1,
    name: 'Оплата дополнительной литературы',
    description: 'Сбор средств для покупки дополнительной литературы',
    route: '/voteListDetails'
  },
  {
    id: 2,
    name: 'Новые занавески',
    description: 'Сбор средств на новые занавески',
    route: '#'
  },
  {
    id: 3,
    name: 'Дополнительное питание',
    description: 'Сбор средств на питание',
    route: '#'
  },
];

export const data = {
  groups: groups,
  participants: participants,
  newParticipants: newParticipants,
  linkItemLogin: linkItemLogin,
  linkItemProfile: linkItemProfile,
  tasks: tasks,
  tariffPlansType: tariffPlansType,
  messages: messages,
  searchResultGroup: searchResultGroup,
  shoppingListItems: shoppingListItems,
  voteListItems: voteListItems,
  groupEvents: groupEvents
}