import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import List from '@material-ui/core/List';
import Badge from '@material-ui/core/Badge';

import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import { COLORS, styles } from '../../styles';
import { Context } from '../../context';
import { Task } from '../../interface';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        padding: '80px 16px 0 16px',
        '& span.MuiTypography-root.MuiListItemText-primary.MuiTypography-body1.MuiTypography-displayBlock': {
            fontFamily: 'Lato',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '14px',
            lineHeight: '20px',
            letterSpacing: '0.1px',
            color: COLORS.darkGrey,
            margin: "0",
            display: "-webkit-box",
            overflow: "hidden",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: "2"
        },
        '& p.MuiTypography-root.MuiListItemText-secondary.MuiTypography-body2.MuiTypography-colorTextSecondary.MuiTypography-displayBlock': {
            margin: "0",
            display: "-webkit-box",
            overflow: "hidden",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: "2"
        },
    },
    header: {
        '& button': {
            padding: '8px'
        },
    },
    accordion: {
        border: '1px solid #e0e4e9',
        boxSizing: 'border-box',
        borderRadius: '4px',
        boxShadow: 'none',
        margin: '0!important',
        '&:before': {
            backgroundColor: 'transparent',
        },
    },
    accordionTitle: {
        '& div.MuiAccordionSummary-content': {
            display: 'flex',
            justifyContent: 'space-evenly',
        },
        '& span': {
            display: 'flex',
            alignItems: 'center'
        },
    },
    accordionContent: {
        padding: 0,
        width: '100%',
        '& span.material-icons': {
            marginRight: '20px'
        },
        '& div.MuiListItem-gutters': {
            paddingLeft: 0,
            paddingRight: 0,
        },
    },
    buttonRed: {
        width: '100%',
    },
    content: {
        height: 'calc(100vh - 160px)',
        overflowY: 'auto',
        boxShadow: 'none',
        paddingTop: '60px',
        paddingBottom: '200px',
    },
    listItem: {
        borderRadius: '8px',
        marginBottom: '16px',
        boxShadow: '0px 12px 20px rgb(156 21 21 / 7%)',
        height: 'fit-content',
        background: COLORS.white,
        '&:hover': {
            background: COLORS.lightGreyTransparent,
        },
    },
    badge: {
        '& .MuiBadge-colorSecondary': {
            backgroundColor: COLORS.red
        }
    },
    notData: {
        fontFamily: "'Montserrat'",
        color: "#95a6b7",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "28px",
        display: "flex",
        alignItems: "center",
        letterSpacing: "0.15px",
        margin: "0 0 0 10px"
    }
});

export default function Desktop() {
    const classes = useStyles();
    const { state: { task, groupEvent, currentGroup } } = useContext(Context);

    const dataTask = task.filter((item: Task) => item.groupId === currentGroup.id);
    const groupEvents = groupEvent.filter((item) => item.viewInDesktop === true);

    return (
        <>
            <Header title={currentGroup.name} />
            <div className={classes.root} >
                <span style={styles.blurTitle} className={classes.header}>
                    <h3 style={styles.h3} >Рабочий стол</h3>
                    <Link
                        to='/groupSettings'>
                        <IconButton>
                            <MoreVertIcon />
                        </IconButton>
                    </Link>
                </span>
                <div className={classes.content}>
                    <Accordion className={classes.accordion} >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className={classes.accordionTitle}
                        >
                            {groupEvents.map(item => (
                                <span key={item.id}>
                                    <Badge
                                        className={classes.badge}
                                        color="secondary"
                                        badgeContent={item.action}
                                        variant="dot">
                                        <Icon >
                                            <img
                                                src={item.image}
                                                alt={item.name}
                                                title={item.name}
                                            />
                                        </Icon>
                                    </Badge>
                                </span>
                            ))}
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className={classes.accordionContent}>
                                {groupEvents.map((item: Task) => (
                                    <Link
                                        to={item.route ? item.route : '#'}
                                        style={styles.link}
                                        key={item.id}
                                    >
                                        <ListItem button>
                                            <Icon >
                                                <img
                                                    src={item.image}
                                                    alt={item.name}
                                                />
                                            </Icon>
                                            <ListItemText primary={item.name} />
                                            {item.action && item.action > 0 ?
                                                <Chip style={styles.chip} label={item.action} /> : null}
                                            {item.id === 2 &&
                                                <Chip style={styles.chip} label={dataTask.length} />}
                                        </ListItem>
                                    </Link>
                                ))}
                            </div>
                        </AccordionDetails>
                    </Accordion>

                    <div style={styles.col}>
                        <span style={styles.title}>
                            <h3 style={styles.h3}>Назначенные задачи</h3>
                        </span>
                        {dataTask && dataTask.length > 0 ? (
                            <List>
                                {dataTask.map((item: any) => (
                                    <div className={classes.listItem} key={item.id}>
                                        <ListItem >
                                            <Link
                                                style={styles.link}
                                                to={`${item.route}/${item.id}`}
                                            >
                                                <ListItemText
                                                    primary={item.name}
                                                    secondary={item.description}
                                                />
                                            </Link>
                                        </ListItem>
                                    </div>
                                ))}
                            </List>
                        ) : (
                            <div className={classes.notData}>
                                <h3>Нет задач</h3>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <Footer route='/newTaskCopy' currentGroup={currentGroup} />
        </>
    );
}
