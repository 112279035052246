import { makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { useForm } from 'react-hook-form';
import Select from '@material-ui/core/Select';
import { COLORS, RedButton, styles } from '../../styles';
import Header from '../Header/Header';
import { useContext, useState } from 'react';
import { GroupType, GroupAccessType } from '../../interface';
import { Context } from '../../context';
import { addGroup } from '../../actions';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        padding: '80px 16px 0 16px',
        '& span.MuiTypography-root.MuiListItemText-primary.MuiTypography-body1.MuiTypography-displayBlock': {
            fontFamily: 'Lato',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '14px',
            lineHeight: '20px',
            letterSpacing: '0.1px',
            color: COLORS.darkGrey,
        },
    },
    listItem: {
        padding: 0,
        marginBottom: '30px'
    },
    form: {
        width: '100%',
        '& div.MuiSelect-outlined': {
            textAlign: 'start'
        },
    }
});

export default function NewGroup() {
    const classes = useStyles();
    const history = useHistory();
    const [type, setType] = useState(GroupType.None);
    const [access, setAccess] = useState(GroupAccessType.None);
    const { dispatch } = useContext(Context);

    const { register, handleSubmit } = useForm({
        defaultValues: {
            name: '',
            description: '',
            type: GroupType.None,
            access: GroupAccessType.None,
        }
    });

    const handleChangeType = (e: any) => {
        setType(e.target.value)
    };

    const handleChangeAccess = (e: any) => {
        setAccess(e.target.value)
    };

    const submitHandler = handleSubmit((data: any) => {
        let groupImg;
        switch (data.type) {
            case 'PTA': {
                groupImg = 'assets/school.svg';
                break;
            }
            case 'Neighbors': {
                groupImg = 'assets/neighbors.svg';
                break;
            }
            case 'Family': {
                groupImg = 'assets/family.svg';
                break;
            }
            case 'Friends': {
                groupImg = 'assets/friends.svg';
                break;
            }
            default: {
                groupImg = 'assets/1.svg';
                break;
            }
        }
        dispatch(
            addGroup({
                name: data.name,
                description: data.description,
                image: groupImg,
                action: 0,
                route: '',
                type: data.type,
                access: data.access,
            })
        );
        history.goBack();
    });

    return (
        <>
            <Header title='Создать новую группу' />
            <div className={classes.root}>
                <span style={styles.title}>
                    <h3 style={styles.h3} >Новая группа</h3>
                </span>
                <form className={classes.form} onSubmit={submitHandler}>
                    <List>
                        {/* <ListItem button style={{ padding: '0 0 8px 0' }}>
                            <input accept='image/*' style={{ display: 'none' }} id='icon-button-file' type='file' />
                            <label htmlFor='icon-button-file'>
                                <IconButton color='primary' aria-label='upload picture' component='span'>
                                    <ImageOutlinedIcon style={{ color: '#95A6B7' }} />
                                </IconButton>
                            </label>
                            <ListItemText primary='Фото гуппы' />
                        </ListItem> */}
                        <ListItem button className={classes.listItem}>
                            <TextField
                                fullWidth
                                label='Название группы'
                                variant='outlined'
                                multiline
                                maxRows={2}
                                size='small'
                                {...register('name', { required: true })}
                            />
                        </ListItem>
                        <ListItem button className={classes.listItem}>
                            <TextField
                                fullWidth
                                label='Описание группы'
                                variant='outlined'
                                multiline
                                maxRows={4}
                                size='small'
                                {...register('description', { required: true })}
                            />
                        </ListItem>
                        <ListItem button className={classes.listItem}>
                            <FormControl variant='outlined' fullWidth size='small'>
                                <InputLabel>Тип группы</InputLabel>
                                <Select
                                    {...register('type', { required: true })}
                                    onChange={handleChangeType}
                                    value={type}
                                    label='Тип группы'
                                >
                                    <MenuItem value='PTA'>Родительский комитет</MenuItem>
                                    <MenuItem value='Neighbors'>Соседи</MenuItem>
                                    <MenuItem value='Family'>Семья</MenuItem>
                                    <MenuItem value='Friends'>Друзья</MenuItem>
                                </Select>
                            </FormControl>
                        </ListItem>
                        <ListItem button className={classes.listItem}>
                            <FormControl variant='outlined' fullWidth size='small'>

                                <InputLabel>Доступность группы</InputLabel>
                                <Select
                                    {...register('access', { required: true })}
                                    value={access}
                                    onChange={handleChangeAccess}
                                    label='Доступность группы'
                                >
                                    <MenuItem value='Open'>Открытая</MenuItem>
                                    <MenuItem value='Closed'>Закрытая</MenuItem>
                                </Select>
                            </FormControl>
                        </ListItem>
                    </List>
                    <span >
                        <RedButton type='submit'>
                            Создать группу
                        </RedButton>
                    </span>
                </form>
            </div>
        </>
    );
}
