import * as React from 'react';
import { makeStyles } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import { useHistory, useParams } from 'react-router-dom';

import { COLORS, GreyButton, RedButton} from '../../../styles';
import Header from '../../Header/Header';
import { useContext } from 'react';
import { Context } from '../../../context';
import { completeTask } from '../../../actions';
import { Task } from '../../../interface';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        padding: '80px 16px 0 16px',
        '& div.MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button ': {
            minHeight: '100px',
        },
        '& ul span.MuiTypography-body1': {
            fontFamily: 'Lato',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '16px',
            lineHeight: '24px',
            letterSpacing: '0.15px',
            color: COLORS.darkGrey,
        },
    },
    card: {
        display: 'flex',
        flexDirection: 'row',
        background: COLORS.white,
        boxShadow: '0px 12px 20px rgba(0, 0, 0, 0.07)',
        borderRadius: '8px',
        '& p': {
            fontFamily: 'Lato',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '14px',
            lineHeight: '20px',
            letterSpacing: '0.25px',
            color: COLORS.lightGrey,
            opacity: 0.6
        },
    },
    button: {
        width: '100%',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between'
    },
    col: {
        display: 'flex',
        flexDirection: 'column',
    },
    sum: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        height: "152px",
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "36px",
        lineHeight: "24px",
        letterSpacing: "0.15px",
        color: COLORS.red
    }
});


export default function ShoppingListDetails() {
    const classes = useStyles();
    const { id } = useParams<{ id: any }>();
    const { state: { task, currentGroup }, dispatch } = useContext(Context);
    const history = useHistory();

    const currentTask = task.find((item: Task) => item.id === +id) as Task;

    const closeTask = (id: number) => {
        dispatch(completeTask(+id));
        history.goBack();
    };

    return (
        <div className={classes.root}>
            <Header title={currentGroup.name} />
            <span className={classes.sum}>
                <p>{currentTask.cash}</p>
            </span>
            <List >
                <ListItem className={classes.card} >
                    <ListItemText
                        primary={currentTask.description}
                    />
                </ListItem>
            </List>
            <div className={classes.row}>
                <span className={classes.button} style={{ width: '156px' }}>
                    <GreyButton onClick={() => { history.goBack() }}>
                        Отмена
                    </GreyButton>
                </span>
                <span className={classes.button} style={{ width: '156px' }}>
                    <RedButton onClick={() => closeTask(id)}>
                        Оплатить
                    </RedButton>
                </span>
            </div>
            <div className={classes.row}>
                <span className={classes.button} >
                    <GreyButton onClick={() => { history.push('/messageList') }}>
                        Обсудить
                    </GreyButton>
                </span>
            </div>
        </div>
    );
}