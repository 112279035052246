import React from 'react';
import { makeStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';

import { COLORS, RedButton, styles } from '../../../../styles';
import Header from '../../../Header/Header';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    padding: '80px 16px 0 16px',

    '& p.MuiFormHelperText-root': {
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '12px',
      lineHeight: '14px',
      display: 'flex',
      alignItems: 'center',
      color: COLORS.lightGrey,
    },
    '& p.MuiFormHelperText-contained': {
      margin: '5px auto',
    },
  },
  button: {
    marginTop: '30px',
    '& button': {
      width: '328px',
      padding: '16px 0px',
      color: COLORS.white,
      backgroundColor: COLORS.red,
      boxShadow: '0px 20px 30px rgba(148, 0, 0, 0.32), inset 0px 0px 3px rgba(148, 0, 0, 0.44)',
      borderRadius: '47px',
      '&:hover': {
        color: COLORS.white,
        backgroundColor: COLORS.red,
        boxShadow: '0px 20px 30px rgba(148, 0, 0, 0.32), inset 0px 0px 3px rgba(148, 0, 0, 0.44)',
      },
      '& span': {
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '24px',
        display: 'flex',
        alignItems: 'center',
        letterSpacing: '1px',
        textTransform: 'uppercase',
        textDecoration: 'none',
      }
    }
  },
});

export default function SearchParticipant() {
  const classes = useStyles();

  return (
    <>
      <Header title='Назад' />
      <div className={classes.root}>
        <span style={styles.title}>
          <h3 style={styles.h3}>Поиск участника</h3>
        </span>
        <div>
          <TextField
            fullWidth
            label='Введите номер телефона'
            variant='outlined'
            multiline
            maxRows={1}
            size='small'
            helperText='Вы можете найти участника по номеру телефона в приложении'
          />
          <div className={classes.button} >
            <RedButton>
              Пригласить
            </RedButton>
          </div>
        </div>
      </div>
    </>
  );
}
