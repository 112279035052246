import { makeStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Pie, Line } from 'react-chartjs-2';
import { ChartOptions } from 'chart.js'

import Header from '../Header/Header';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        padding: '80px 16px 0 16px',
    },
    content: {
        height: 'calc(100vh - 80px)',
        overflowY: 'auto',
        boxShadow: 'none',
        paddingTop: '20px',
        paddingBottom: '20px',
    },
    сard: {
        marginBottom: '16px',
        '& .MuiPaper-rounded': {
            borderRadius: "8px"
        }
    },
});

export default function Charts() {
    const classes = useStyles();

    const dataPie = {
        labels: ['Red', 'Blue', 'Yellow', 'Green'],
        datasets: [
            {
                label: '# of Votes',
                data: [10, 15, 70, 5],
                backgroundColor: ['#FF6060', '#FFACAC', '#ffcfcf', '#FF3030',],
                borderWidth: 0,
            },
        ],
    };

    const optionsPie: ChartOptions = {
        legend: {
            position: 'bottom',
            display: true,
        },
        maintainAspectRatio: true
    };

    const dataLine = {
        labels: ['1', '2', '3', '4', '5', '6'],
        datasets: [
            {
                label: '# of Votes',
                data: [12, 19, 3, 5, 2, 3],
                fill: false,
                backgroundColor: '#FF6060',
                borderColor: 'rgba(255, 99, 132, 0.2)',
            },
        ],
    };

    return (
        <div className={classes.root}>
            <Header title='Отчеты' />

            <div className={classes.content}>
                <Card className={classes.сard} variant="outlined">
                    <CardContent>
                        <Pie
                            data={dataPie}
                            width={100}
                            height={100}
                            options={optionsPie}
                        />
                    </CardContent>
                </Card>

                <Card className={classes.сard} variant="outlined">
                    <CardContent>
                        <Line
                            data={dataLine}
                            width={100}
                            height={100}
                        />
                    </CardContent>
                </Card>
            </div>
        </div>
    );
}
