import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import CloseIcon from '@material-ui/icons/Close';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import { COLORS, styles } from "../../styles";

const useStyles = makeStyles({
    root: {
        '& div.MuiDialog-paper': {
            padding: '16px',
        },
        "& span.material-icons": {
            marginRight: "20px"
        },
    },
    dialogContent: {
        padding: 0,
    },
    button: {
        padding: '8px'
    },
    icon: {
        color: COLORS.lightGrey,
    }
});

export default function SearchAction() {
    const classes = useStyles();
    const [state, setState] = React.useState({
        open: false,
        checkedA: true,
        checkedB: false
    });

    const handleClickOpen = () => {
        setState({ ...state, open: true });
    };

    const handleClose = () => {
        setState({ ...state, open: false });
    };

    return (
        <div>
            <IconButton onClick={handleClickOpen} className={classes.button}>
                <MoreVertIcon />
            </IconButton>

            <Dialog
                open={state.open}
                onClose={handleClose}
                className={classes.root}
            >
                <span style={styles.title}>
                    <h3 style={styles.h3}>Школа №18, класс 1”А”</h3>
                    <IconButton aria-label="delete" onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </span>
                <DialogContent className={classes.dialogContent}>
                    <List>
                        <Link to='#' style={styles.link}>
                            <ListItem button >
                                <ListItemText
                                    primary='Посмотреть описание группы'
                                />
                            </ListItem>
                        </Link>
                        <Link to='#' style={styles.link}>
                            <ListItem button>
                                <ListItemText
                                    primary='Подать заявку'
                                />
                            </ListItem>
                        </Link>
                    </List>
                </DialogContent>
            </Dialog>
        </div>
    );
}
