import firebase from "firebase/compat/app";
import 'firebase/compat/firestore';
import 'firebase/compat/auth'

const firebaseConfig = {
    apiKey: "AIzaSyADREcmkVybzGMMj9_d3yX7e8ZyZw5sq_w",
    authDomain: "testpaybox-b70e1.firebaseapp.com",
    projectId: "testpaybox-b70e1",
    storageBucket: "testpaybox-b70e1.appspot.com",
    messagingSenderId: "397495956327",
    appId: "1:397495956327:web:36163d70574433944735c2"
};

export const app = firebase.initializeApp(firebaseConfig);
export const auth = app.auth();
export const db = app.firestore();