import { Link } from 'react-router-dom';
import { useContext } from 'react';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import { data } from "../../data";
import { COLORS, styles } from "../../styles";
import { Context } from 'src/context';
import './Footer.scss';

export interface LeftMenuProps {
    open: boolean;
    onClose: (value: any) => void;
}

const useStyles = makeStyles({
    list: {
        overflowY: 'auto',
        boxShadow: 'none',
    },
    listItem: {
        borderRadius: '8px',
        margin: '16px 16px 0 0',
        boxShadow: '0px 12px 20px rgb(156 21 21 / 7%)',
        height: 'fit-content',
        background: COLORS.white,
        '&:hover': {
            background: COLORS.lightGreyTransparent,
        },
        '& li.listItemCard': {
            margin: '16px 0 16px 0',
        },
    },
    menu: {
        margin: '16px',
    },
    avatar: {
        width: 100,
        height: 100,
        color: COLORS.red,
        '&.MuiAvatar-colorDefault': {
            background: '#bdbdbd4f',
        },
        '& svg': {
            width: 40,
            height: 40,
        }
    },
});

export default function LeftMenuCopy(props: LeftMenuProps) {
    const classes = useStyles();
    const { onClose, open } = props;
    const { state: { user }, auth } = useContext(Context);

    return (
        <Drawer
            open={open}
            onClick={onClose}
        >
            <div className={classes.menu} >
                {user.image ? (
                    <Avatar className={classes.avatar} src={user.image}>
                    </Avatar>
                ) : (
                    <Avatar className={classes.avatar}>
                        <PersonOutlineOutlinedIcon />
                    </Avatar>
                )}
                <List>
                    <ListItem className='listItem'>
                        <ListItemText
                            primary={user.login}
                            secondary={user.phone}
                        />
                    </ListItem>
                </List>
                <List>
                    <Link to='/profileSetting' style={styles.link}>
                        <ListItem button className='listItem'>
                            <ListItemIcon >
                                <SettingsOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText primary='Настройки' />
                        </ListItem>
                    </Link>
                </List>
                <span className='title'>
                    <h5 style={styles.h5} >Вход/выход</h5>
                </span>
                <List>
                    {data.linkItemLogin.map((link) => (
                        <Link key={link.id} to={link.route} style={styles.link} onClick={() => auth.signOut()}>
                            <ListItem button className='listItem'>
                                <ListItemIcon>{link.image}</ListItemIcon>
                                <ListItemText primary={link.name} />
                            </ListItem>
                        </Link>
                    ))}
                </List>
            </div>
        </Drawer>
    );
}
