import { IconButton, IconButtonProps, Checkbox, CheckboxProps } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import Radio, { RadioProps } from '@material-ui/core/Radio';

export const COLORS = {
    white: '#fff',
    black: '#000',
    red: '#ff3030',
    lightGrey: '#95A6B7',
    lightGreyTransparent: '#00000014',
    darkGrey: '#4A545F',
  }

const chip: React.CSSProperties = {
    backgroundColor: COLORS.red,
    borderRadius: '19px',
    minWidth: '20px',
    height: '20px',
    color: COLORS.white,
    fontFamily: 'Lato, Arial, serif',
    fontStyle: 'normal',
    fontWeight: 350,
    fontSize: '8px',
    lineHeight: '130%',
};

const title: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    height: '64px',
    alignItems: 'center',
    justifyContent: 'start',
    textAlign: 'left'
};

const blurTitle: React.CSSProperties = {
    position: 'fixed',
    zIndex: 999,
    backdropFilter: 'blur(5px)',
    flexGrow: 1,
    boxShadow: 'none',
    justifyContent: "space-between",
    width: 'calc(100% - 32px)',
    display: 'flex',
    flexDirection: 'row',
    height: '64px',
    alignItems: 'center',
    textAlign: 'left',
    // background: `linear-gradient(0deg, rgb(255 255 255 / 23%), rgb(255 255 255), rgb(237 239 240))`
};

const h3: React.CSSProperties = {
    flexGrow: 1,
    fontFamily: 'Lato',
    color: COLORS.darkGrey,
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '24px',
    letterSpacing: '0.15px',
    margin: 0,
    lineHeight: '28px',
}

const h2: React.CSSProperties = {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '0.15px',
    color: COLORS.lightGrey,
    flexGrow: 1,
}

const h5: React.CSSProperties = {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.1px',
    color: COLORS.lightGrey,
    margin: 0,
    flexGrow: 1,
}

const row: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
}

const col: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
}

const link: React.CSSProperties = {
    textDecoration: 'none',
    width: '100%'
}

const secondaryText: React.CSSProperties = {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '24px',
    color: COLORS.lightGrey,
    opacity: 0.6
}

const avatar: React.CSSProperties = {
    minWidth: "40px",
    marginRight: '16px'
}

export const RedCheckbox = withStyles({
    root: {
        color: COLORS.lightGrey,
        '&$checked': {
            color: COLORS.red,
        },
    },
    checked: {},
})((props: CheckboxProps) => <Checkbox color='default' {...props} />);

export const RedButton = withStyles({
    root: {
        marginTop: '24px',
        width: '100%',
        minWidth: '156px',
        maxWidth: '328px',
        padding: '16px 0px',
        color: COLORS.white,
        backgroundColor: COLORS.red,
        boxShadow: '0px 20px 30px rgba(148, 0, 0, 0.32), inset 0px 0px 3px rgba(148, 0, 0, 0.44)',
        borderRadius: '47px',
        '&:hover': {
            color: COLORS.white,
            backgroundColor: COLORS.red,
            boxShadow: '0px 20px 30px rgba(148, 0, 0, 0.32), inset 0px 0px 3px rgba(148, 0, 0, 0.44)',
        },
        '& span': {
            fontFamily: 'Lato',
            fontStyle: 'normal',
            fontWeight: '600',
            fontSize: '16px',
            lineHeight: '24px',
            display: 'flex',
            alignItems: 'center',
            letterSpacing: '1px',
            textTransform: 'uppercase',
            textDecoration: 'none',
        }
    },
})((props: IconButtonProps) => <IconButton color='default' {...props} />);

export const GreyButton = withStyles({
    root: {
        marginTop: '30px',
        width: '100%',
        minWidth: '156px',
        maxWidth: '328px',
        padding: '16px 0px',
        color: COLORS.darkGrey,
        backgroundColor: '#E0E4E9',
        boxShadow: '0px 18px 21px rgba(149, 166, 183, 0.35)',
        borderRadius: '47px',
        '&:hover': {
            color: COLORS.darkGrey,
            backgroundColor:  '#E0E4E9',
            boxShadow: '0px 18px 21px rgba(149, 166, 183, 0.35)',
        },
        '& span': {
            fontFamily: 'Lato',
            fontStyle: 'normal',
            fontWeight: '600',
            fontSize: '16px',
            lineHeight: '24px',
            display: 'flex',
            alignItems: 'center',
            letterSpacing: '1px',
            textTransform: 'uppercase',
            textDecoration: 'none',
        }
    },
})((props: IconButtonProps) => <IconButton color='default' {...props} />);

export const RedRadio = withStyles({
    root: {
        color: '#EEEEEE',
        '&$checked': {
            color: COLORS.red
        },
    },
    checked: {},
})((props: RadioProps) => <Radio color="default" {...props} />);

export const styles = {
    chip: chip,
    h3: h3,
    h2: h2,
    title: title,
    h5: h5,
    link: link,
    row: row,
    col: col,
    secondaryText: secondaryText,
    avatar: avatar,
    blurTitle: blurTitle,
    COLORS: COLORS
}