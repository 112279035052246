export enum ActionType {
    ADD_GROUP,
    DELETE_GROUP,
    EDIT_DES_GROUP,
    CHECK_USER,
    COMPLETE_TASK,
    ADD_TASK,
    ADD_PARTICIPANT,
    DELETE_PARTICIPANT,
    CURRENT_GROUP,
    DISPLAY_GROUP_EVENT,
    ENABLE_NOTIFICATION,
    PIN_GROUP,
    EDIT_USER_PROFILE,
}

export enum GroupType {
    None = '',
    PTA = 'Родительский комитет',
    Neighbors = 'Соседи',
    Family = 'Семья',
    Friends = 'Друзья',
}

export enum GroupAccessType {
    None = '',
    Open = 'Открытая',
    Closed = 'Закрытая',
}

export enum TypeEducationalInstitution {
    None = '',
    School = 'Школа',
    Preschool = 'Дошкольное образование',
    Additional = 'Факультатив',
}

export enum TaskType {
    None = '',
    Vote = 'Голосование',
    Payment = 'Оплата',
    Shopping = 'Покупки',
}

export enum SettingType { string, number, boolean }

export interface Group {
    id?: number,
    name?: string;
    description?: string,
    image?: string,
    action?: number,
    route?: string;
    type?: GroupType,
    access?: GroupAccessType,
    users?: User[],
    requests?: User[],
}

export interface Task {
    id?: number,
    name?: string;
    description?: string,
    image?: string,
    action?: number,
    route?: string;
    groupId?: number,
    cash?: string
}

export interface GroupEvent {
    id?: number,
    name?: string;
    description?: string;
    image?: string,
    action?: number,
    route?: string;
    viewInDesktop?: boolean,
    viewInPreviewGroup?: boolean,
    type?: SettingType;
    value?: boolean;
}

export interface Participant {
    id?: number,
    name: string;
    description?: string;
    image: string,
}

export interface User {
    id?: string,
    docId?: string,
    login?: string;
    password?: string,
    image?: string,
    phone?: string,
    email?: string,
}

export interface AppState {
    group: Group[];
    task: Task[];
    isNotification: boolean;
    isAnchor: boolean;
    groupEvent: GroupEvent[];
    participant: Participant[];
    currentGroup: Group;
    user: User;
}

export type Action =
    { type: ActionType.ADD_GROUP; payload: Group }
    | { type: ActionType.DELETE_GROUP; payload: number }
    | { type: ActionType.EDIT_DES_GROUP; payload: Group }
    | { type: ActionType.CHECK_USER; payload: User }
    | { type: ActionType.EDIT_USER_PROFILE; payload: User }
    | { type: ActionType.COMPLETE_TASK; payload: number }
    | { type: ActionType.ADD_TASK; payload: Task }
    | { type: ActionType.DISPLAY_GROUP_EVENT; payload: GroupEvent }
    | { type: ActionType.ADD_PARTICIPANT; payload: Participant }
    | { type: ActionType.DELETE_PARTICIPANT; payload: number[] }
    | { type: ActionType.CURRENT_GROUP; payload: Group }
    | { type: ActionType.ENABLE_NOTIFICATION; payload: boolean }
    | { type: ActionType.PIN_GROUP; payload: boolean }

export interface AppModel {
    state: AppState;
    dispatch: React.Dispatch<Action>;
    app: any;
    auth: any;
    db: any;
}