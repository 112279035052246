import * as React from 'react';
import { makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import { COLORS, RedButton, styles } from '../../styles';
import Header from '../Header/Header';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        padding: '80px 16px 0 16px',
    },
    button: {
        width: '100%',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        alignItems: 'center'
    },
    field: {
        '& div.MuiSelect-outlined': {
            textAlign: 'start'
        },
    },
    chip: {
        margin: '25px 16px 0 0',
        height: '30px',
        background: '#E0E4E9',
        borderRadius: '24px',
        '& span.MuiChip-label': {
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: '600',
            fontSize: '12px',
            lineHeight: '120%',
            display: 'flex',
            alignItems: 'center',
            textAlign: 'center',
            color: COLORS.darkGrey,
        },
        '&:hover': {
            background: COLORS.red
        },
        '&:hover span.MuiChip-label': {
            color: COLORS.white,
        },
    }
});

export default function Search() {
    const classes = useStyles();

    return (
        <>
            <Header title='Назад' />
            <div className={classes.root}>
                <span style={styles.title}>
                    <h3 style={styles.h3}>Поиск</h3>
                </span>
                <div>
                    <div className={classes.row}>
                        <TextField
                            fullWidth
                            label='Введите имя или название '
                            helperText='Вы можете найти участника или группу'
                            variant='outlined'
                            size='small'
                            className={classes.field}
                        />
                    </div>
                    <div className={classes.row}>
                        <Chip label='Участники' clickable className={classes.chip} />
                        <Chip label='Группы' clickable className={classes.chip} />
                    </div>
                </div>
                <div className={classes.row}>
                    <span className={classes.button} >
                        <Link to='/searchResult' style={styles.link}>
                            <RedButton>
                                Найти
                            </RedButton>
                        </Link>
                    </span>
                </div>
            </div>
        </>

    );
}
