import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { IconButton, makeStyles } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useHistory } from 'react-router-dom';
import { COLORS } from '../../styles';
import './Header.scss';

const useStyles = makeStyles({
  icon: {
    color: COLORS.lightGrey,
  }
});

export default function Header(props: any) {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className='header'>
      <AppBar>
        <Toolbar>
          <div >
            <IconButton
              edge='start'
              size='small'
              className={classes.icon}
              onClick={() => { history.goBack() }}
            >
              <ArrowBackIosIcon fontSize='inherit' />
            </IconButton>
            <span onClick={() => { history.goBack() }}>
              <p>{props.title}</p>
            </span>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}

