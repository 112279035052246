import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from '@material-ui/core/Icon';
import Chip from '@material-ui/core/Chip';

import { styles } from "../../../../styles";
import Header from '../../../Header/Header';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    padding: '80px 16px 0 16px',
    "& span.material-icons": {
      marginRight: "20px"
    },
    '& div.MuiListItem-gutters': {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
});

export default function AddParticipant() {
  const classes = useStyles();

  return (
    <>
      <Header title='Новый участник' />

      <div className={classes.root}>
        <span style={styles.title}>
          <h3 style={styles.h3}>Добавить участника</h3>
        </span>
        <div>
          <List>
            <Link to='/searchParticipant' style={styles.link}>
              <ListItem button >
                <Icon>
                  <img src="/assets/search_dark.svg" alt="" />
                </Icon>
                <ListItemText
                  primary='Найти участника'
                />
              </ListItem>
            </Link>
            <Link to='/requestParticipant' style={styles.link}>
              <ListItem button>
                <Icon>
                  <img src="/assets/photo.svg" alt="" />
                </Icon>
                <ListItemText
                  primary='Запросы на участие'
                />
                <Chip style={styles.chip} label='2' />
              </ListItem>
            </Link>
          </List>
        </div>
      </div>
    </>
  );
}
