import React from 'react';
import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import Button from '@material-ui/core/Button';
import SendIcon from '@material-ui/icons/Send';
import Header from '../Header/Header';
import { COLORS } from '../../styles';
import { useContext } from 'react';
import { Context } from '../../context/index';
import firebase from "firebase/compat/app";
import 'firebase/compat/firestore';
import { useCollectionData } from 'react-firebase-hooks/firestore';
// import { ChatEngine } from 'react-chat-engine';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '85vh',
        padding: '80px 16px 0 16px',
    },
    container: {
        height: '80vh',
        overflowY: 'auto',
    },
    bubbleContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '130%',
        color: COLORS.darkGrey,
        textAlign: 'start'
    },
    left: {
        // alignSelf: 'start',
        // display: 'flex',
        flexDirection: 'column',
        // maxWidth: '70%',
        marginTop: '17px',
        padding: '5px 0',
        '& span': {
            fontFamily: 'Lato',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '12px',
            lineHeight: '120%',
            color: COLORS.lightGrey,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'end',
            marginRight: '20px'
        },
        '& div': {
            background: 'rgba(224, 228, 233, 0.4)',
            borderRadius: '8px 8px 8px 0px',
            display: 'flex',
            padding: '15px',
        },
    },
    right: {
        // alignSelf: 'end',
        // display: 'flex',
        flexDirection: 'column',
        // maxWidth: '70%',
        marginTop: '17px',
        padding: '5px 0',
        '& span': {
            fontFamily: 'Lato',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '12px',
            lineHeight: '120%',
            color: COLORS.lightGrey,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'start',
            marginLeft: '20px'
        },
        '& div': {
            display: 'flex',
            background: 'rgba(147, 171, 225, 0.2)',
            borderRadius: '8px 8px 0px 8px',
            padding: '15px',
        },
    },
    form: {
        // padding: "2px 4px",
        display: "flex",
        alignItems: "center",
        height: '48px',
        border: `1px solid ${COLORS.lightGrey}`,
        boxSizing: 'border-box',
        borderRadius: '8px',
        padding: '8px',
        '& div.MuiInputBase-root': {
            width: '85%',
            fontFamily: 'Lato',
            fontStyle: 'normal',
            fontWeight: '325',
            fontSize: '12px',
            lineHeight: '130%',
            color: COLORS.darkGrey,
        },
    },
    input: {
        marginLeft: 10,
        flex: 1
    },
    iconButton: {
        padding: 0,
        minWidth: '32px',
        height: '32px',
        backgroundColor: COLORS.red,
        '& span svg': {
            color: COLORS.white,
            width: '14px'
        },
        '&:hover': {
            backgroundColor: COLORS.red,
        },
    },
});

export default function Chat() {
    const classes = useStyles();

    const { db, state: { user, currentGroup } } = useContext(Context);
    const [value, setValue] = useState('')
    const [messages] = useCollectionData(
        db.collection('messages').orderBy('createdAt')
    )

    const sendMessage = async () => {
        db.collection('messages').add({
            uid: user.id,
            displayName: user.login,
            photoURL: user.image || '',
            text: value,
            createdAt: firebase.firestore.FieldValue.serverTimestamp()
        })
        setValue('')
    }

    return (
        <>
            <Header title='Назад' />

            {/* <ChatEngine
                height='100vh'
                userName='demoUser'
                userSecret='123'
                projectID='80f4eff8-ac40-4703-a883-07322dc63e84'
            /> */}
            <div className={classes.root}>
                <div className={classes.container}>
                    {messages && messages.map((message, index) =>
                        <div
                            key={index}
                            style={{
                                margin: 10,
                                marginLeft: user.id === message.uid ? 'auto' : '10px',
                                width: 'fit-content',
                                padding: 5,
                            }}
                            className={user.id === message.uid ? classes.right : classes.left}>
                            <div key={index++}> {message.text}</div>
                        </div>
                    )}
                </div>

                <div className={classes.form}>
                    <InputBase
                        value={value}
                        className={classes.input}
                        placeholder="Введите сообщение"
                        onChange={(e) => setValue(e.target.value)}
                    />
                    <Button
                        onClick={sendMessage}
                        type='submit'
                        className={classes.iconButton}
                    >
                        <SendIcon />
                    </Button>
                </div>
            </div>
        </>
    );
}
