import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Link } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import PhoneEnabledOutlinedIcon from '@material-ui/icons/PhoneEnabledOutlined';
import firebase from "firebase/compat/app";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { collection, query, where, getDocs } from "firebase/firestore";

import LoginWithPhoneAction from './LoginWithPhoneAction';
import Header from '../../components/Header/Header';
import { styles, RedButton, RedCheckbox } from '../../styles';
import { Context } from '../../context';
import { checkUser } from '../../actions';
import './index.scss';

export default function Login() {
  const history = useHistory();
  const { dispatch, auth, db } = useContext(Context);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickClose = () => {
    setOpen(false);
  };

  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      email: 'demoUser@gmail.com',
      password: '123456',
    }
  });

  useEffect(() => {
    setTimeout(() => {
      reset({
        email: 'demoUser@gmail.com',
        password: '123456'
      });
    }, 1000);
  }, [reset]);

  const signInWithEmailAndPass = handleSubmit(async (e: any) => {
    const { email, password } = e;
    const auth = getAuth();

    await signInWithEmailAndPassword(auth, email, password)
      .then((userCredential: any) => {
        const user = userCredential.user;
        getUser(user.uid);
        history.push('/groupList');
      })
      .catch((error: any) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log('error ', errorCode, errorMessage);
      })
  });

  const getUser = async(userId :string) => {
    const q = query(collection(db, "users"), where("uid", "==", userId));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const user = doc.data();
      
      dispatch(
        checkUser({
          id: user.uid,
          docId: doc.id,
          login: user.displayName,
          password:user.password,
          image: user.photoURL,
          phone: user.phoneNumber,
          email:user.email,
        }))
    });
  }

  const signInWithGoogle = async () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    try {
      const res = await auth.signInWithPopup(provider);
      const user = res.user;
      const query = await db
        .collection("users")
        .where("uid", "==", user.uid).get();

      dispatch(
        checkUser({
          id: user.uid,
          login: user.displayName,
          password: user.password,
          email: user.email,
          image: user.photoURL,
          phone: user.phoneNumber
        }))
      history.push('/groupList');

      if (query.docs.length === 0) {
        await db.collection("users").add({
          uid: user.uid,
          login: user.displayName,
          authProvider: "google",
          email: user.email,
        });
      }
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <>
      <Header title='Назад' />
      <div className='login'>
        <span style={styles.title}>
          <h3 style={styles.h3} >Вход</h3>
        </span>
        <form className='form' onSubmit={signInWithEmailAndPass}>
          <List>
            <ListItem button className='items'>
              <TextField
                fullWidth
                label='Почта'
                variant='outlined'
                multiline
                maxRows={2}
                size='small'
                style={{ marginBottom: '20px' }}
                {...register('email', { required: true })}
              />
            </ListItem>
            <ListItem button className='items'>
              <TextField
                fullWidth
                label='Пароль'
                variant='outlined'
                multiline
                maxRows={4}
                size='small'
                {...register('password', { required: true })}
              />
            </ListItem>
          </List>

          <div className='formRemamber' >
            <FormControlLabel
              control={
                <RedCheckbox
                  name='Запомнить'
                />
              }
              label='Запомнить'
              className='subTitle'
            />
            <span>
              <Link to='#' className='subTitle' style={styles.link}>
                Забыли пароль?
              </Link>
            </span>
            <LoginWithPhoneAction open={open} onClose={handleClickClose} />
          </div>

          <RedButton type='submit' onClick={signInWithEmailAndPass}>
            Войти
          </RedButton>

          <span style={{ display: 'flex', justifyContent: 'center', margin: '24px 0' }} className='subTitle'>
            <Link to='/register' style={styles.link} className='subTitle'>
              Ещё нет аккаунта?
            </Link>
          </span>

        </form>
        <div className='messagers'>
          <IconButton onClick={signInWithGoogle} className='fabButton'>
            <img src='assets/google.svg' alt='google' />
          </IconButton>
          <IconButton>
            <img src='assets/facebook.svg' alt='facebook' />
          </IconButton>
          <IconButton className='fabButton'>
            <img src='assets/linkedin.svg' alt='linkedin' />
          </IconButton>
          <IconButton onClick={handleClickOpen} className='fabButton'>
            <PhoneEnabledOutlinedIcon />
          </IconButton>
        </div>
        <LoginWithPhoneAction open={open} onClose={handleClickClose} />
      </div>
    </>
  );
}