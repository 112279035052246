import * as React from 'react';
import { makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { GreyButton, RedButton, styles } from '../../../styles';
import Header from '../../Header/Header';
import { useContext } from 'react';
import { Context } from 'src/context';
import { useForm } from 'react-hook-form';
import { addTask } from 'src/actions';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        padding: '80px 16px 0 16px',
    },
    button: {
        width: '100%',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between'
    },
    form: {
        '& div:first-child': {
            marginTop: 0,
        },
    },
    field: {
        marginTop: '30px',
        '& div.MuiSelect-outlined': {
            textAlign: 'start'
        },
    },
    listItem: {
        padding: 0,
        marginBottom: '30px'
    },
});

export default function NewVoteCopy() {
    const { state: { currentGroup } } = useContext(Context);
    const classes = useStyles();
    const history = useHistory();
    const { dispatch } = useContext(Context);

    const { register, handleSubmit } = useForm({
        defaultValues: {
            description: '',
        }
    });
    
    const submitHandler = handleSubmit((data: any) => {
        dispatch(
            addTask({
                name: 'Голосование',
                description: data.description,
                image: '/assets/scale.svg',
                route: '/voteListDetails',
                groupId: currentGroup.id,
            })
        );
        history.push('/desktop');
    });

    return (
        <>
            <Header title={currentGroup.name} />
            <div className={classes.root}>
                <span style={styles.title}>
                    <h3 style={styles.h3}>Создание задачи</h3>
                </span>
                <form className={classes.form} onSubmit={submitHandler}>
                    <List>
                        <ListItem button className={classes.listItem}>
                            <TextField
                                fullWidth
                                label='Краткое описание'
                                placeholder="Введите краткое описание"
                                variant='outlined'
                                multiline
                                minRows={10}
                                maxRows={10}
                                size='small'
                                className={classes.field}
                                {...register('description')}
                            />
                        </ListItem>
                    </List>
                    <span >
                        <RedButton type='submit'>
                            Создать задачу
                        </RedButton>
                    </span>
                </form>
            </div>
        </>
    );
}
