import { MemoryRouter, Route, Switch } from "react-router-dom";
import { Provider } from '../../context';

import routes from "src/routes";
import './index.scss';

function App() {
  return (
    <Provider>
      <div className="App">
        <MemoryRouter>
          <Switch>
            {routes.map((route, i) => (
              <Route key={i} exact path={route.path} component={route.component} />
            ))}
          </Switch>
        </MemoryRouter>
      </div>
    </Provider>
  );
}

export default App;